/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import clsx from "clsx";
import get from "lodash.get";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ACTIONS, MODALS, QUOTE_STATUSES, QUOTE_TYPES, STATUSES } from "../../../constants";
import { currencyFormatter, dateFormatter } from "../../../formatters";
import { stringifyParams } from "../../../helpers/stringifyParams";
import Button from "../../components/Button";
import H3 from "../../components/H3";
import Icon from "../../components/Icon";
import Status from "../../components/Status";
import { useAuth, useModal } from "../../hooks";
import { useAttachmentQuery } from "../../hooks/useAttachmentQuery";
import AHQuoteCard from "./AHQuoteCard";

const getPremiumChangeLabel = ({ quoteData, value }) => {
  if (quoteData?.base?.additional_premium || value?.amount === 0) {
    return "Amount due";
  }

  return "Amount owed";
};

const defaultColumns = [
  {
    displayName: "Ref",
    id: "type",
    formatter: ({ type, index }) =>
      type === QUOTE_TYPES.DEFAULT || type === QUOTE_TYPES.COMMERCIAL
        ? "Quote"
        : `Option ${index + 1}`,
    extractValue: ({ quoteData, index }) => ({ type: quoteData.type, index }),
  },
  { displayName: "Deductible", formatter: currencyFormatter, path: "base.deductible" },
  {
    displayName: "Premium",
    formatter: (amount) => currencyFormatter({ amount }),
    path: "rates.grossPremium",
  },
];

const endoColumns = [
  {
    displayName: "Ref",
    id: "type",
    formatter: ({ type, index }) =>
      type === QUOTE_TYPES.DEFAULT ||
      type === QUOTE_TYPES.COMMERCIAL ||
      type === QUOTE_TYPES.ENDORSEMENT
        ? "Quote"
        : `Option ${index + 1}`,
    extractValue: ({ quoteData, index }) => ({ type: quoteData.type, index }),
  },
  {
    displayName: "Effective from",
    formatter: dateFormatter,
    path: "base.effective_from",
  },
  {
    displayName: getPremiumChangeLabel,
    formatter: ({ amount }) => currencyFormatter({ amount: Math.abs(amount) }),
    extractValue: ({ quoteData }) => {
      if (quoteData?.base?.refund_premium) {
        return quoteData?.base?.refund_premium;
      }

      if (quoteData?.base?.additional_premium) {
        return quoteData?.base?.additional_premium;
      }

      return { amount: 0, currency: "USD" };
    },
  },
];

const bySubmitted = (quoteData) => quoteData.status === QUOTE_STATUSES.SUBMITTED;

const QuoteRow = ({
  columns,
  contractData,
  handleBindClick,
  handleQuoteClick,
  isCurrent,
  isExpandable,
  isExpanded,
  quoteData,
  rowIndex,
}) => {
  const isDefault = quoteData.type === QUOTE_TYPES.DEFAULT;
  const isSubmitted = quoteData.status === QUOTE_STATUSES.SUBMITTED;
  const { checkPermissions, isProducingBroker } = useAuth(contractData);

  return (
    <>
      <tr
        className={clsx(
          "border-b border-gray-300 last:border-b-0 h-16 hover:bg-gray-50 transition",
          isDefault && isSubmitted && "bg-blue-100 font-semibold",
          isExpandable && "cursor-pointer"
        )}
        onClick={isExpandable ? handleQuoteClick(quoteData.id, isExpanded) : undefined}
      >
        {columns.map((column, index) => {
          const { displayName, path, formatter, extractValue } = column;
          const value =
            extractValue instanceof Function
              ? extractValue({ quoteData, contractData, index: rowIndex })
              : get(quoteData, path);
          const formattedValue = formatter instanceof Function ? formatter(value) : value;
          const name =
            displayName instanceof Function
              ? displayName({ contractData, quoteData, value })
              : displayName;

          return (
            <td key={name} className={index === 0 ? "pl-6" : undefined}>
              {formattedValue ?? value}
            </td>
          );
        })}
        <td>
          <Status kind="small" status={quoteData.status} />
        </td>
        <td className="w-24">
          {isSubmitted && isCurrent && checkPermissions(ACTIONS.BIND_QUOTE) && (
            <Button kind="primary" className="h-10 px-6" onClick={handleBindClick(quoteData.id)}>
              Bind
            </Button>
          )}
        </td>
        {isExpandable && (
          <td>
            <Icon
              name={isExpanded ? "chevron-up" : "chevron-down"}
              className={clsx("mx-4 fill-current", isExpanded && "text-blue-600")}
            />
          </td>
        )}
      </tr>

      {isExpanded && isExpandable && (
        <tr>
          <td colSpan="6">
            <div>
              <table className="w-full">
                <tbody>
                  {quoteData?.base?.limit_required && (
                    <tr className="border-b border-gray-300 h-16">
                      <td className="pl-6 w-1/2">Limit required</td>
                      <td className="pr-6">{currencyFormatter(quoteData.base.limit_required)}</td>
                    </tr>
                  )}

                  <tr className="border-b border-gray-300 h-16">
                    <td className="pl-6 w-1/2">Inception date</td>
                    <td className="pr-6">{dateFormatter(quoteData.base.inception_date)}</td>
                  </tr>

                  <tr className="border-b border-gray-300 h-16">
                    <td className="pl-6 w-1/2">Expiry date</td>
                    <td className="pr-6">{dateFormatter(quoteData.base.expiry_date)}</td>
                  </tr>

                  {quoteData?.base?.verbiage && (
                    <tr className="border-b border-gray-300 h-16">
                      <td className="pl-6 w-1/2">M&D premium</td>
                      <td className="pr-6">
                        <div className="py-2">
                          <span>
                            {currencyFormatter({
                              amount: quoteData?.rates?.grossPremium || 0,
                              currencyDisplay: "code",
                            })}
                          </span>{" "}
                          {quoteData?.base?.verbiage}
                        </div>
                      </td>
                    </tr>
                  )}

                  <tr className="border-b border-gray-300 h-16">
                    <td className="pl-6 w-1/2">TRIPRA Premium</td>
                    <td className="pr-6">
                      {currencyFormatter({
                        amount:
                          quoteData?.base?.tripra === "No"
                            ? 0
                            : quoteData?.rates?.tripraAmount || 0,
                      })}
                    </td>
                  </tr>

                  {!isProducingBroker &&
                    contractData?.brokerCommissions?.commissionBreakdown?.map((item) => (
                      <tr className="border-b border-gray-300 h-16">
                        <td className="pl-6 w-1/2">{item.name} commission</td>
                        <td className="pr-6">{`${item.commission}%`}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

const QuoteCard = ({
  contractData,
  expandAll,
  heading,
  headingText,
  isCurrent,
  isEndorsement,
  quotesData,
}) => {
  const { showModal } = useModal();

  const { location, push } = useHistory();
  const { applyEndoAttachment } = useAttachmentQuery();
  const endorsementId = isEndorsement ? contractData.id : undefined;
  const { productRef, contractId } = useParams();

  const defaultQuote = quotesData.find((quote) => quote.type === QUOTE_TYPES.DEFAULT);
  const commercialQuote = quotesData.find((quote) => quote.type === QUOTE_TYPES.COMMERCIAL);

  const areSubmitted = quotesData.every(bySubmitted);
  const allIds = quotesData.map((quoteData) => quoteData.id);
  const initialIds = expandAll ? allIds : [areSubmitted && commercialQuote?.id];
  const [selectedIds, setSelectedIds] = useState(initialIds);

  const rest = quotesData.filter((quote) => quote.type !== QUOTE_TYPES.DEFAULT);

  const sortedQuotesData = defaultQuote ? [defaultQuote, ...rest] : rest;
  const contractColumns = areSubmitted
    ? defaultColumns
    : defaultColumns.filter((c) => c.id !== "type" && c.id !== "ref");
  const columns = isEndorsement ? endoColumns : contractColumns;

  const handleQuoteClick = (quoteId, expanded) => (event) => {
    const nextIds = expanded
      ? selectedIds.filter((id) => id !== quoteId)
      : [...selectedIds, quoteId];

    event.stopPropagation();

    return setSelectedIds(nextIds);
  };

  const handleBindSuccess = async (nextContractData) => {
    if (nextContractData.status === STATUSES.APPLIED) {
      await applyEndoAttachment({ productRef, contractId, endorsementId });
    }

    if (nextContractData.status === STATUSES.BOUND) {
      return showModal(MODALS.BIND_QUOTE_SUCCESS);
    }

    return false;
  };

  const handleBindClick = (quoteId) => (event) => {
    event.stopPropagation();
    push({ pathname: location.pathname, search: stringifyParams({ quoteId, endorsementId }) });

    showModal(isEndorsement ? MODALS.BIND_ENDORSEMENT_QUOTE : MODALS.BIND_QUOTE, {
      callback: handleBindSuccess,
    });
  };

  return (
    <div className="bg-white shadow rounded mb-4">
      {heading}
      {headingText && <H3 className="mb-4 p-4">{headingText}</H3>}
      <div>
        <table className="w-full">
          <thead className="text-left text-sm ">
            <tr className="mb-3 ">
              {columns.map((column, index) => {
                const { displayName, path, extractValue } = column;
                const quoteData = quotesData[0];
                const value =
                  extractValue instanceof Function
                    ? extractValue({ quoteData, contractData })
                    : get(quoteData, path);
                const name =
                  displayName instanceof Function
                    ? displayName({ contractData, quoteData, value })
                    : displayName;

                return (
                  <th className={clsx("font-normal", index === 0 && "pl-6")} key={name}>
                    <div className="mb-3">{name}</div>
                  </th>
                );
              })}
              <th />
              <th />
              <th className="w-10" />
            </tr>
          </thead>
          <tbody>
            {sortedQuotesData.map((quoteData, index) => (
              <QuoteRow
                columns={columns}
                contractData={contractData}
                handleQuoteClick={handleQuoteClick}
                isCurrent={isCurrent}
                isExpandable={!isEndorsement}
                isExpanded={selectedIds.includes(quoteData.id)}
                key={quoteData.id}
                handleBindClick={handleBindClick}
                quoteData={quoteData}
                quotesData={quotesData}
                rowIndex={index}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

QuoteCard.propTypes = {
  quotesData: PropTypes.array,
};

QuoteCard.defaultProps = {
  quotesData: [],
};

export default process.env.DEFAULT_PRODUCT_REF === "ah" ? AHQuoteCard : QuoteCard;
