import get from "lodash.get";
import moment from "moment";
import { compile } from "path-to-regexp";
import React, { memo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { AUDIT_EVENTS, ROUTES } from "../../../constants";
import { CONTRACT_TYPES } from "../../../constants/contractTypes";
import * as api from "../../api";
import Button from "../../components/Button";
import Card from "../../components/Card";
import H3 from "../../components/H3";
import Status from "../../components/Status";
import { downloadDocument } from "../../helpers/downloadDocument";
import { useTimeAgo } from "../../hooks";
import ContractDetails from "../ContractDetails";

const getAutor = (data) => {
  if (data.type === AUDIT_EVENTS.DOCUMENT) {
    return get(data, "data.createdByUserFullName");
  }

  if (data.type === AUDIT_EVENTS.SNAPSHOT) {
    return (
      get(data, "data.snapshot.updatedBy.fullName") || get(data, "data.snapshot.createdBy.fullName")
    );
  }

  if (data.type === AUDIT_EVENTS.RENEWAL) {
    return get(data, "data.updatedBy.fullName") || get(data, "data.createdBy.fullName");
  }

  if (data.type === AUDIT_EVENTS.MESSAGE) {
    return get(data, "data.updatedBy.fullName") || get(data, "data.createdBy.fullName");
  }

  return "";
};

const getActivity = (data, params) => {
  const snapshot = get(data, "data.snapshot", {});
  const isEndorsement = snapshot.type === CONTRACT_TYPES.ENDORSEMENT;

  if (data.type === AUDIT_EVENTS.DOCUMENT) {
    if (data.data.documentType === "message_attachment") {
      return "attached a file to a conversation";
    }

    if (data.data.documentType === "attachment") {
      return "attached a file to a policy";
    }

    return "uploaded a file";
  }

  if (data.type === AUDIT_EVENTS.MESSAGE) {
    return "wrote a message";
  }

  if (data.type === AUDIT_EVENTS.RENEWAL) {
    const { renewals = [] } = data.data;
    const renewal = renewals[renewals.length - 1];

    return (
      <span>
        created a renewal quote{" "}
        <Link
          className="text-blue-500"
          to={compile(ROUTES.CONTRACT_VIEW)({ ...params, contractId: renewal.id })}
        >
          #{renewal.ref}
        </Link>
      </span>
    );
  }

  if (data.type === AUDIT_EVENTS.SNAPSHOT && isEndorsement) {
    if (snapshot.createdAt === snapshot.updatedAt) {
      return `created endorsement #${snapshot.ref}`;
    }

    return `changed endorsement #${snapshot.ref}`;
  }

  if (data.type === AUDIT_EVENTS.SNAPSHOT && !isEndorsement) {
    if (snapshot.createdAt === snapshot.updatedAt) {
      return `created policy #${snapshot.ref}`;
    }

    return `changed policy #${snapshot.ref}`;
  }

  return "";
};

const getStatus = (data) => {
  if (data.type === AUDIT_EVENTS.SNAPSHOT) {
    return data.data.snapshot.status;
  }

  return false;
};

const Item = ({ data, productRef, contractId, schemaData, clauseData }) => {
  const author = getAutor(data);
  const params = useParams();
  const activity = getActivity(data, params);
  const status = getStatus(data);
  const timestamp = new Date(data.updatedAt || data.createdAt).getTime();
  const timeago = useTimeAgo(timestamp);
  const time = moment(timestamp).format("llll");
  const [isContractVisible, setContractVisible] = useState();
  const { mutateAsync: getAttachmentLink, ...getAttachmentLinkQuery } = useMutation(
    api.getAttachmentLink
  );

  const handleDownloadClick = async () => {
    const res = await getAttachmentLink({
      contractId,
      productRef,
      documentId: data.data.documentId,
    });
    const { url, fileName } = get(res, "data.data");

    downloadDocument(url, fileName);
  };

  return (
    <Card
      className="mb-8 "
      headingText={
        <div>
          <span className="text-base">{author}</span>
          <span className="text-base"> {activity} </span>
          <span className="text-base" title={time}>
            {timeago}
          </span>
        </div>
      }
      tag={status && <Status status={status} kind="small" />}
    >
      <>
        {data.type === AUDIT_EVENTS.DOCUMENT && (
          <div className="p-4">
            <div className="mb-4">{data.data.fileName}</div>
            <Button
              isDisabled={getAttachmentLinkQuery.isLoading}
              kind="secondary"
              className="h-10 px-4"
              onClick={handleDownloadClick}
            >
              Download
            </Button>
          </div>
        )}

        {data.type === AUDIT_EVENTS.MESSAGE && <div className="p-4">{data.data.content}</div>}

        {data.type === AUDIT_EVENTS.SNAPSHOT && (
          <div className="p-4 font-medium text-base">
            <Button
              isDisabled={getAttachmentLinkQuery.isLoading}
              kind="secondary"
              className="h-10 px-4"
              onClick={() => setContractVisible(!isContractVisible)}
            >
              {!isContractVisible && "Show details"}
              {isContractVisible && "Hide details"}
            </Button>

            {isContractVisible && (
              <div className="p-4  mt-2 rounded bg-gray-100">
                <ContractDetails
                  clauseData={clauseData}
                  schemaData={schemaData}
                  contractData={data.data.snapshot}
                  isEndorsement={data.data.snapshot.type === "endorsement"}
                  allowChangesToggle={data.data.patches.length > 0}
                  snapshotData={data.data.snapshots}
                />
              </div>
            )}
          </div>
        )}
      </>
    </Card>
  );
};

const Items = ({ auditData, clauseData, contractId, productRef, schemaData }) => (
  <div>
    {auditData.map((item) => (
      <Item
        clauseData={clauseData}
        contractId={contractId}
        data={item}
        key={item.id}
        productRef={productRef}
        schemaData={schemaData}
      />
    ))}
  </div>
);

const areEqual = (prevProps, nextProps) =>
  JSON.stringify(prevProps.auditData.length) === JSON.stringify(nextProps.auditData.length);

const MemItems = memo(Items, areEqual);

const ContractViewAudit = ({ clauseData, schemaData }) => {
  const { contractId, productRef } = useParams();

  const auKey = ["audit", { productRef, contractId }];
  const auditQuery = useQuery(auKey, api.getAudit, {
    enabled: Boolean(contractId),
    refetchInterval: JSON.parse(process.env.CLIENT_AUDIT_INTERVAL),
  });
  const auditData = get(auditQuery, "data.data.data", []);

  return (
    <>
      <H3 className="mb-8">Audit log</H3>

      <MemItems
        auditData={auditData}
        clauseData={clauseData}
        contractId={contractId}
        productRef={productRef}
        schemaData={schemaData}
      />
    </>
  );
};

export default ContractViewAudit;
