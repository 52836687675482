import clsx from "clsx";
import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ACTIONS, KEY_CODES } from "../../../constants";
import Button from "../../components/Button";
import Form from "../../components/Form";
import Icon from "../../components/Icon";
import Loader from "../../components/Loader";
import { downloadDocument } from "../../helpers/downloadDocument";
import { toBase64 } from "../../helpers/toBase64";
import { useAuth, useStickyState } from "../../hooks";
import { useAttachmentQuery } from "../../hooks/useAttachmentQuery";
import { useMessagesQuery } from "../../hooks/useMessagesQuery";
import Message from "./Message";
import UploadButton from "./UploadButton";

const SIZES = {
  DEFAULT: "DEFAULT",
  MINIMIZED: "MINIMIZED",
  EXPANDED: "EXPANDED",
};

const MessageBox = ({ handleClose, className }) => {
  const { checkPermissions } = useAuth();
  const {
    uploadAttachment,
    isUploading,
    getAttachmentLink,
    uploadAttachmentQuery,
    getAttachmentLinkQuery,
  } = useAttachmentQuery();

  const { isLoading, messagesData, createMessage, isCreating } = useMessagesQuery();

  const [size, setSize] = useStickyState("default", "messageBoxSize");
  const isMinimizedSize = size === SIZES.MINIMIZED;
  const isExpandedSize = size === SIZES.EXPANDED;

  const { contractId, productRef } = useParams();

  const [formValues, setFormValues] = useState({ message: "" });
  const [messageError, setMessageError] = useState();
  const { user } = useAuth();
  const messageListNode = useRef();

  const handleChange = (event) => {
    setFormValues({ [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    const isValid = formValues.message.trim().length > 0;

    if (isValid) {
      await createMessage({
        data: {
          type: ACTIONS.CREATE_MESSAGE,
          payload: formValues,
        },
        productRef,
        contractId,
      });

      setFormValues({ message: "" });
    }
  };

  const handleKeyDown = (event) => {
    if (event.metaKey && event.keyCode === KEY_CODES.ENTER) {
      handleSubmit(event);
    }
  };

  useEffect(() => {
    const messageListEl = messageListNode.current;

    if (messageListEl) {
      messageListEl.scrollIntoView(false);
      messageListEl.scrollTop = messageListEl.scrollHeight;
    }
  }, [messagesData.length]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMessageError();
    }, 5000);

    return () => clearTimeout(timeout);
  }, [messageError]);

  useEffect(() => {
    const cloned = cloneDeep(messagesData);
    const nextId = (cloned.pop() || {}).id;
    const key = `user#${user.id}#contract#${contractId}#last_known_message_id`;

    if (nextId) {
      window.localStorage.setItem(key, JSON.stringify(nextId));
    }
  }, [messagesData, contractId, user.id]);

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    const fileSizeInBase64 = (file.size + 814) * 1.37;
    const sizeThreshold = (6000000 - 814) / 1.37;
    const content = await toBase64(file);

    if (fileSizeInBase64 > sizeThreshold) {
      return false;
    }

    try {
      const res = await uploadAttachment({
        contractId,
        productRef,
        data: { content, fileName: file.name, documentType: "message_attachment" },
      });
      const { documentId, fileName, documentSize } = get(res, "data.data");

      return createMessage({
        data: {
          type: ACTIONS.UPLOAD_MESSAGE_ATTACHMENT,
          payload: { documentId, fileName, documentSize },
        },
        productRef,
        contractId,
      });
    } catch (error) {
      return setMessageError({ type: ACTIONS.UPLOAD_MESSAGE_ATTACHMENT });
    }
  };

  const handleFileClick = async (message) => {
    if (getAttachmentLinkQuery.isLoading) {
      return false;
    }

    const { documentId } = message.content;
    const res = await getAttachmentLink({ contractId, productRef, documentId });
    const { url, fileName } = get(res, "data.data");

    return downloadDocument(url, fileName);
  };

  return (
    <div
      className={clsx(
        "fixed bottom-0 right-0 mr-8  rounded-t shadow-lg z-10 overflow-hidden",
        {
          "w-96": !isExpandedSize,
          "w-160": isExpandedSize,
          "bg-gray-100 border-l border-t border-r border-gray-500": !isMinimizedSize,
          "bg-blue-700 text-white": isMinimizedSize,
        },
        className
      )}
    >
      <div className={clsx("p-4 font-medium", { "border-b border-gray-400": !isMinimizedSize })}>
        Messages
        <div className="flex items-center absolute right-0 top-0 mr-4 mt-4">
          {!isMinimizedSize && (
            <Icon
              name="minimize"
              className={clsx(
                "cursor-pointer w-6 p-1 ml-3 fill-current text-gray-800 hover:bg-gray-300 rounded"
              )}
              onClick={() => setSize(SIZES.MINIMIZED)}
            />
          )}

          {isMinimizedSize && (
            <Icon
              name="maximize"
              className={clsx(
                "cursor-pointer w-6 p-1 ml-3 fill-current text-white hover:bg-blue-600 rounded"
              )}
              onClick={() => setSize(SIZES.DEFAULT)}
            />
          )}

          {isExpandedSize && (
            <Icon
              name="unfold-less"
              className={clsx(
                "cursor-pointer w-6 p-1 ml-3 fill-current text-gray-800 hover:bg-gray-300 rounded"
              )}
              onClick={() => setSize(SIZES.DEFAULT)}
            />
          )}

          <Icon
            name="cross"
            className={clsx("cursor-pointer w-6 p-1 ml-3 fill-current rounded", {
              "text-gray-800 hover:bg-gray-300 ": !isMinimizedSize,
              "text-white hover:bg-blue-600": isMinimizedSize,
            })}
            onClick={handleClose}
          />
        </div>
      </div>

      {!isMinimizedSize && (
        <>
          <div
            ref={messageListNode}
            className={clsx("px-4 pt-4 pb-12 overflow-y-auto bg-white relative", {
              "h-96": !isExpandedSize,
              "h-108": isExpandedSize,
              "outline-4 outline-red-900": messageError,
            })}
          >
            {messageError && (
              <div className="absolute inset-x-0 top-0 bg-red-900 text-white text-xs px-2 py-1 font-semibold">
                {messageError.type === ACTIONS.UPLOAD_MESSAGE_ATTACHMENT &&
                  get(uploadAttachmentQuery, "error.response.data.error")}
                {!messageError.type === ACTIONS.UPLOAD_MESSAGE_ATTACHMENT && "An error occured."}
              </div>
            )}

            {isLoading && (
              <div className="flex items-center justify-center h-64">
                <Loader />
              </div>
            )}

            {!isLoading &&
              messagesData.map((message) => (
                <Message
                  key={message.id}
                  message={message}
                  user={user}
                  handleFileClick={handleFileClick}
                />
              ))}
          </div>

          <div className="border-t border-gray-400 relative">
            <Form
              onSubmit={handleSubmit}
              onKeyDown={handleKeyDown}
              className={clsx("p-4", { flex: isExpandedSize })}
            >
              <textarea
                className={clsx(
                  "text-gray-800 resize-none border-b placeholder-gray-600 border-gray-300 block w-full mr-2 mb-2 bg-gray-100 focus:outline-none ",
                  { "h-24": isExpandedSize }
                )}
                placeholder="Write a message..."
                onChange={handleChange}
                value={formValues.message}
                name="message"
              />
              <div className={clsx("flex justify-end", { "items-end": isExpandedSize })}>
                {checkPermissions(ACTIONS.UPLOAD_MESSAGE_ATTACHMENT) && (
                  <UploadButton onChange={handleUpload} isDisabled={isUploading}>
                    Attach file
                  </UploadButton>
                )}

                <Button
                  kind="primary"
                  type="submit"
                  className="h-10 px-6"
                  isDisabled={!formValues.message || isCreating}
                >
                  Send
                </Button>
              </div>
            </Form>
          </div>
        </>
      )}
    </div>
  );
};

export default MessageBox;
