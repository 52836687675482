import get from "lodash.get";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import * as api from "../api";
import useAuth from "./useAuth";

export const useMessagesQuery = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const params = useParams();
  const { productRef, contractId } = params;
  const key = `user#${user.id}#contract#${contractId}#last_known_message_id`;
  const value = window.localStorage.getItem(key);
  const parsedValue = value === "undefined" ? null : value;
  const lastKnownMessageId = JSON.parse(parsedValue);

  const mKey = [
    "messages",
    { contractId, productRef },
    { last_known_message_id: lastKnownMessageId },
  ];
  const messagesQuery = useQuery(mKey, () => api.getMessages({ queryKey: mKey }), {
    enabled: Boolean(contractId),
    refetchInterval: JSON.parse(process.env.CLIENT_MESSAGES_INTERVAL),
  });

  const { mutateAsync: createMessage, ...createMessageQuery } = useMutation(api.createMessage, {
    onSuccess: (data) => {
      window.localStorage.setItem(key, JSON.stringify(data.data.messageId));
      queryClient.invalidateQueries(mKey);
      queryClient.setQueryData(["contract", { productRef, contractId }], data);
    },
  });

  const messagesData = get(messagesQuery, "data.data.data", []);
  const unreadCount = get(messagesQuery, "data.data.unreadCount", 0);
  const { isLoading } = messagesQuery;
  const isCreating = createMessageQuery.isLoading;

  return {
    messagesQuery,
    createMessage,
    isCreating,
    isLoading,
    messagesData,
    unreadCount,
    lastKnownMessageId,
  };
};
