import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import set from "lodash.set";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { ACTIONS, STATUSES } from "../../constants";
import { currencyFormatter } from "../../formatters";
import ajv from "../../helpers/ajv";
import { resolveSchema } from "../../helpers/resolveSchema";
import * as api from "../api";
import Banner from "../components/Banner";
import Button from "../components/Button";
import Form from "../components/Form";
import InputCheckbox from "../components/InputCheckbox";
import Modal from "../components/Modal";
import Table from "../components/Table";
import mapChildren from "../helpers/mapChildren";
import { useAuth, useContractQuery, useQuoteQuery } from "../hooks";
import AHBindQuoteModal from "./AHBindQuoteModal";

const { Body, Data, Row } = Table;

const BindQuoteModalForm = ({ callback, handleClose, quoteData, schemaData, contractData }) => {
  const [rates, setRates] = useState(quoteData.rates);

  const { checkPermissions } = useAuth();
  const {
    contractId,
    endorsementId,
    isUpdating,
    productRef,
    quoteId,
    updateQuote,
  } = useQuoteQuery();

  const { mutateAsync: checkReferrals, ...checkReferralsQuery } = useMutation(api.checkReferrals);
  const checkReferralsQueryData = get(checkReferralsQuery, "data.data.data", []);

  const { mutateAsync: checkRater, ...checkRaterQuery } = useMutation(api.checkRater, {
    onSuccess: (data) => setRates(data.data.data),
  });

  const willBeReferred = checkReferralsQueryData.length > 0;

  const [formValues, setFormValues] = useState(quoteData.base);
  const [isBindConfirmed, setBindConfirmed] = useState(false);

  const clonedSchema = cloneDeep(schemaData);
  const resolvedSchema = resolveSchema(clonedSchema, formValues);
  const clonedValues = cloneDeep(formValues);

  const validate = ajv.compile(schemaData);
  const isValid = validate(clonedValues);

  const handleChange = (...args) => {
    const clonedFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;

      set(clonedFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;

      set(clonedFormValues, name, value);
    }

    checkRater({
      data: {
        type: ACTIONS.CHECK_RATES,
        payload: { ...quoteData.rates, tripra: clonedFormValues.tripra },
      },
    });

    checkReferrals({
      data: { nextSubmission: clonedFormValues, prevSubmission: contractData.submission },
    });

    setFormValues(clonedFormValues);
  };

  const handleSubmit = async () => {
    const res = await updateQuote({
      contractId,
      data: {
        type: ACTIONS.BIND_QUOTE,
        payload: {
          binding: {
            ...formValues,
            premium: { amount: rates.grossPremium, currency: "USD" },
          },
        },
      },
      endorsementId,
      productRef,
      quoteId,
    });

    handleClose();
    callback(get(res, "data.data"));
  };

  useEffect(() => {
    setBindConfirmed(false);
  }, [willBeReferred]);

  return (
    <div className="p-6">
      <Table className="mb-8">
        <Body>
          <Row className="border-b border-gray-500 last:border-0">
            <Data>Limit required</Data>
            <Data>{currencyFormatter(quoteData.base.limit_required)}</Data>
          </Row>

          <Row className="border-b border-gray-500 last:border-0">
            <Data>Deductible</Data>
            <Data>{currencyFormatter(quoteData.base.deductible)}</Data>
          </Row>

          <Row className="border-b border-gray-500 last:border-0">
            <Data>TRIPRA Premium</Data>
            <Data>
              {currencyFormatter({
                amount: formValues.tripra === "No" ? 0 : quoteData?.rates?.tripraAmount || 0,
              })}
            </Data>
          </Row>

          <Row className="border-b border-gray-500 last:border-0">
            <Data className="font-bold">Gross premium</Data>
            <Data className="font-bold">
              {currencyFormatter({ amount: rates.grossPremium.toFixed(2) })}
            </Data>
          </Row>
        </Body>
      </Table>

      <Form onSubmit={handleSubmit}>
        {mapChildren({
          formValues,
          onChange: handleChange,
          parentKey: "",
          parentSchema: resolvedSchema,
          setFormValues,
          validationErrors: validate.errors,
        })}

        {willBeReferred && (
          <Banner
            className="mb-8"
            color="red"
            headingText="This quote will be referred."
            key={willBeReferred}
          >
            <div className="mb-4">
              {checkPermissions(ACTIONS.VIEW_REFERRAL_REASONS) &&
                (checkReferralsQueryData || []).map((reason) => <p key={reason}>{reason}</p>)}
            </div>

            <InputCheckbox
              labelText="I’d like to refer this quote"
              name="confirm_bind"
              onChange={() => setBindConfirmed(!isBindConfirmed)}
              isChecked={isBindConfirmed}
            />
          </Banner>
        )}

        {isValid && !willBeReferred && (
          <Banner
            key={formValues.inceptionDate}
            className="mb-8 shadow-none  bg-gray-50"
            color="orange"
            headingText="Are you sure you want to bind this quote? This cannot be undone."
          >
            <InputCheckbox
              labelText="I understand"
              name="confirm_bind"
              onChange={() => setBindConfirmed(!isBindConfirmed)}
              isChecked={isBindConfirmed}
            />
          </Banner>
        )}

        <div className="flex">
          <Button
            className="mr-4"
            isDisabled={
              !isValid ||
              !isBindConfirmed ||
              isUpdating ||
              checkRaterQuery.isLoading ||
              checkReferralsQuery.isLoading
            }
            kind="primary"
            type="submit"
          >
            Submit
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

const BindQuoteModal = ({ handleClose, callback }) => {
  const { contractData, isLoading: isContractLoading } = useContractQuery();
  const { quoteData, isLoading: isQuoteLoading, schemaData } = useQuoteQuery();
  const isLoading = isContractLoading || isQuoteLoading;

  return (
    <Modal
      handleClose={handleClose}
      headingText={!isLoading && "Bind quote"}
      isLoading={isLoading}
      clearParamsOnClose
    >
      <BindQuoteModalForm
        callback={callback}
        handleClose={handleClose}
        quoteData={quoteData}
        contractData={contractData}
        schemaData={schemaData?.properties?.[STATUSES.BOUND]}
      />
    </Modal>
  );
};

BindQuoteModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default process.env.DEFAULT_PRODUCT_REF === "ah" ? AHBindQuoteModal : BindQuoteModal;
