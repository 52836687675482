import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Button from "../components/Button";
import Form from "../components/Form";
import InputCalendar from "../components/InputCalendar";
import Modal from "../components/Modal";
import { useContractQuery } from "../hooks";
import AHSubmitEndorsementModal from "./AHSubmitEndorsementModal";

const SubmitEndorsementModalForm = ({ contractData, formData, handleClose, handleSubmitForm }) => {
  const [formValues, setFormValues] = useState({ effective_from: undefined });

  const inceptionIso = contractData?.boundQuote?.base?.inception_date;
  const effectiveFromIso = contractData?.submission?.quote?.effective_from;
  const expiryIso = contractData?.boundQuote?.base?.expiry_date;
  const effectiveFromDate = moment(effectiveFromIso || inceptionIso).toDate();
  const expiryDate = moment(expiryIso).toDate();

  const handleChange = (...args) => {
    const clonedFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;

      set(clonedFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;

      set(clonedFormValues, name, value);
    }

    setFormValues(clonedFormValues);
  };

  const handleSubmit = async () => {
    handleClose();
    handleSubmitForm({
      ...formData,
      quote: {
        ...contractData?.submission?.quote,
        ...formData.quote,
        is_bespoke: "No",
        effective_from: formValues.effective_from,
      },
    });
  };

  return (
    <div className="p-6">
      <Form onSubmit={handleSubmit}>
        <InputCalendar
          className="mb-8"
          dayPickerProps={{ initialMonth: effectiveFromDate }}
          disableDaysAfter={expiryDate}
          disableDaysBefore={effectiveFromDate}
          isRequired
          labelText="It is requested this endorsement will be agreed with effect from:"
          name="effective_from"
          onChange={handleChange}
          value={formValues.effective_from}
        />

        <div className="flex">
          <Button
            className="mr-4"
            isDisabled={!formValues.effective_from}
            kind="primary"
            type="submit"
          >
            Submit
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

const SubmitEndorsementModal = ({ formData, handleClose, handleSubmitForm }) => {
  const { contractData, isLoading } = useContractQuery();

  return (
    <Modal
      handleClose={handleClose}
      isLoading={isLoading}
      headingText={!isLoading && "Submit endorsement"}
    >
      <SubmitEndorsementModalForm
        contractData={contractData}
        formData={formData}
        handleClose={handleClose}
        handleSubmitForm={handleSubmitForm}
      />
    </Modal>
  );
};

SubmitEndorsementModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default process.env.DEFAULT_PRODUCT_REF === "ah"
  ? AHSubmitEndorsementModal
  : SubmitEndorsementModal;
