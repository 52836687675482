import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import React from "react";
import Button from "../Button";
import Card from "../Card";
import H4 from "../H4";

const SectionRepeater = ({
  className,
  formValues,
  id,
  item,
  mapChildren,
  onChange,
  ownKey,
  setFormValues,
  value,
  validationErrors,
}) => {
  const hasInitialValue = value && Array.isArray(value) && value.length > 0;
  const initialItems = hasInitialValue ? value : [{}];

  const schemaItem =
    item.items.type === "object" ? item.items : { properties: { [id]: item.items } };

  const handleAdd = () => {
    const cloned = cloneDeep(formValues);

    set(cloned, ownKey, [...initialItems, {}]);
    setFormValues(cloned);
  };

  const handleRemove = (itemIndex) => {
    const cloned = cloneDeep(formValues);
    const filtered = initialItems.filter((v, index) => index !== itemIndex);

    set(cloned, ownKey, filtered);
    setFormValues(cloned);
  };

  return (
    <div className={className}>
      <H4 className="my-8">{item.title}</H4>

      {initialItems.map((lineItem, index) => {
        const key = `${ownKey}[${index}]`;

        return (
          <Card
            className="mb-8"
            key={key}
            header={
              <Card.Header className="flex justify-between items-center p-4">
                <div className="font-medium">
                  {item.items.title} #{index + 1}
                </div>

                {initialItems.length > 1 && (
                  <Button
                    className="h-10 px-3 -m-2"
                    kind="danger-secondary"
                    onClick={() => handleRemove(index)}
                  >
                    Delete
                  </Button>
                )}
              </Card.Header>
            }
          >
            <div className="p-4">
              {mapChildren({
                formValues,
                onChange,
                parentKey: key,
                parentSchema: schemaItem,
                setFormValues,
                validationErrors,
                // useParentKey: true,
              })}
            </div>
          </Card>
        );
      })}

      <Button onClick={handleAdd}>Add new</Button>
    </div>
  );
};

export default SectionRepeater;
