/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import clsx from "clsx";
import { compile } from "path-to-regexp";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { ACTIONS, ROUTES } from "../../../constants";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import useAuth from "../../hooks/useAuth";

const NavItem = ({ children, to }) => (
  <div className="flex items-center justify-center mr-2">
    <NavLink
      to={to}
      className="text-gray-400 transition cursor-pointer font-medium text-sm flex px-3 py-2 rounded"
      activeClassName="bg-gray-800 text-gray-400"
    >
      {children}
    </NavLink>
  </div>
);

const NavBar = () => {
  const { checkPermissions, subTenants, isInternalEnv } = useAuth();
  const { productRef } = useParams();
  const [isDropdownVisible, setIsDropdownVisible] = useState();
  const { user, tenant } = useAuth();
  const dropdownButtonRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const canShowBranding = process.env.DEFAULT_PRODUCT_REF !== "ah";

  const handleOutsideClick = (event) => {
    const { target } = event;
    const buttonNode = dropdownButtonRef.current;
    const menuNode = dropdownMenuRef.current;

    if (buttonNode && !buttonNode.contains(target) && menuNode && !menuNode.contains(target)) {
      setIsDropdownVisible(false);
    }
  };

  const handleDropdownClick = (event) => {
    event.stopPropagation();
    setIsDropdownVisible(!isDropdownVisible);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => document.removeEventListener("mousedown", handleOutsideClick);
  });

  return (
    <div
      className={clsx(
        "fixed z-30 inset-x-0 top-0 h-15 px-8 flex items-center",
        canShowBranding ? "bg-gray-900" : "bg-black"
      )}
    >
      <div className="flex items-center">
        <Link to={productRef ? compile(ROUTES.PRODUCT)({ productRef }) : "/"}>
          {canShowBranding && (
            <img src="/logo.png" className="w-24 cursor-pointer" alt="Convex Logo" />
          )}

          {!canShowBranding && (
            <img
              src="/artificial-logo-white.svg"
              className="w-24 cursor-pointer text-white"
              alt="Artificial Logo"
            />
          )}
        </Link>
      </div>

      <div className="flex ml-8">
        {productRef && <NavItem to={compile(ROUTES.INBOX)({ productRef })}>Team inbox</NavItem>}

        {productRef && checkPermissions(ACTIONS.VIEW_REFERRALS_TAB) && (
          <NavItem to={compile(ROUTES.REFERRALS)({ productRef })}>Referred</NavItem>
        )}

        {productRef && <NavItem to={compile(ROUTES.DRAFTS)({ productRef })}>Quotes</NavItem>}

        {productRef && <NavItem to={compile(ROUTES.CONTRACTS)({ productRef })}>Policies</NavItem>}
      </div>

      <div className="ml-auto flex ">
        {checkPermissions(ACTIONS.CREATE_DRAFT_SUBMISSION) && (
          <Button
            className="h-10 my-4 mr-4"
            to={compile(ROUTES.CONTRACT_NEW)({ productRef })}
            as={Link}
            kind="primary"
            aria-label="Create quote"
          >
            Create quote
          </Button>
        )}

        <div className="relative">
          <div
            className={clsx(
              "flex items-center transition cursor-pointer p-4 hover:text-gray-100 ",
              isDropdownVisible && "bg-gray-1000"
            )}
            onClick={handleDropdownClick}
            ref={dropdownButtonRef}
          >
            <div>
              <p className="text-gray-600 text-sm">
                {tenant.name} ({user.role})
              </p>
              <p className="text-gray-300 text-sm font-medium">{user.fullName}</p>
            </div>
            <Icon name="chevron-down" className="fill-current text-white ml-8" />
          </div>

          {isDropdownVisible && (
            <div
              className="absolute shadow-lg border border-gray-300 rounded bg-white left-0 right-0 z-10"
              ref={dropdownMenuRef}
            >
              {isInternalEnv && (
                <div className="p-4 text-sm">
                  <p className="mb-2 font-medium">Child Organisations</p>
                  <ul className="list-disc list-inside">
                    {subTenants.map((subTenant) => (
                      <li className="mb-1" key={subTenant.id}>
                        {subTenant.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <Link to={ROUTES.LOGOUT}>
                <div className="h-12 border-t border-gray-300 flex items-center p-4 text-sm font-medium hover:bg-gray-50 cursor-pointer">
                  <Icon name="logout" className="fill-current text-gray-800 mr-4" />
                  Log Out
                </div>
              </Link>
            </div>
          )}
        </div>
        <span className="inline-flex items-center text-sm ml-4 font-medium text-gray-400">
          {process.env.npm_package_version}
        </span>
      </div>
    </div>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
};

NavItem.defaultProps = {
  children: undefined,
  to: undefined,
};

export default NavBar;
