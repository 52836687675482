import get from "lodash.get";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { ACTIONS } from "../../constants";
import * as api from "../api";
import Banner from "../components/Banner";
import Button from "../components/Button";
import Form from "../components/Form";
import InputCheckbox from "../components/InputCheckbox";
import Modal from "../components/Modal";
import { useContractQuery, useQuoteQuery } from "../hooks";

const AHBindQuoteModalForm = ({ callback, handleClose }) => {
  const {
    contractId,
    endorsementId,
    isUpdating,
    productRef,
    quoteId,
    updateQuote,
  } = useQuoteQuery();

  const { mutateAsync: checkReferrals, ...checkReferralsQuery } = useMutation(api.checkReferrals);
  const [isBindConfirmed, setBindConfirmed] = useState(false);

  const handleSubmit = async () => {
    const res = await updateQuote({
      contractId,
      data: { type: ACTIONS.BIND_QUOTE },
      endorsementId,
      productRef,
      quoteId,
    });

    handleClose();
    callback(get(res, "data.data"));
  };

  return (
    <div className="p-6">
      <Form onSubmit={handleSubmit}>
        <Banner
          className="mb-8 shadow-none  bg-gray-50"
          color="orange"
          headingText="Are you sure you want to bind this quote? This cannot be undone."
        >
          <InputCheckbox
            labelText="I understand"
            name="confirm_bind"
            onChange={() => setBindConfirmed(!isBindConfirmed)}
            isChecked={isBindConfirmed}
          />
        </Banner>

        <div className="flex">
          <Button
            className="mr-4"
            isDisabled={!isBindConfirmed || isUpdating || checkReferralsQuery.isLoading}
            kind="primary"
            type="submit"
          >
            Submit
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

const AHBindQuoteModal = ({ handleClose, callback }) => {
  const { contractData, isLoading: isContractLoading } = useContractQuery();
  const { quoteData, isLoading: isQuoteLoading } = useQuoteQuery();
  const isLoading = isContractLoading || isQuoteLoading;

  return (
    <Modal
      handleClose={handleClose}
      headingText={!isLoading && "Bind quote"}
      isLoading={isLoading}
      clearParamsOnClose
    >
      <AHBindQuoteModalForm
        callback={callback}
        handleClose={handleClose}
        quoteData={quoteData}
        contractData={contractData}
      />
    </Modal>
  );
};

AHBindQuoteModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default AHBindQuoteModal;
