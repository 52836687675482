import { compile } from "path-to-regexp";
import React from "react";
import { useQuery } from "react-query";
import { Link, Route, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { ACTIONS, MODALS, ROUTES, STATUSES } from "../../../constants";
import * as api from "../../api";
import Button from "../../components/Button";
import PageLoader from "../../components/PageLoader";
import Table from "../../components/Table";
import { useAuth, useModal } from "../../hooks";
import { ModalSwitch } from "../../hooks/useModal";
import { usePrivateRoute } from "../../hooks/usePrivateRoute";
import { useProductCheck } from "../../hooks/useProductCheck";
import { useScrollTop } from "../../hooks/useScrollTop";
import NavBar from "../NavBar";
import { columns, filters } from "./config";

const Contracts = () => {
  const { push } = useHistory();
  const { productRef } = useParams();
  const isPoliciesRoute = useRouteMatch(ROUTES.CONTRACTS) && ROUTES.CONTRACTS;
  const isDraftsRoute = useRouteMatch(ROUTES.DRAFTS) && ROUTES.DRAFTS;
  const isReferralsRoute = useRouteMatch(ROUTES.REFERRALS) && ROUTES.REFERRALS;
  const { checkPermissions } = useAuth();

  const params = {
    status: (filters[isPoliciesRoute || isDraftsRoute || isReferralsRoute] || []).join(","),
  };
  const contractsQuery = useQuery(
    ["contracts", productRef, params],
    () => api.getContracts({ productRef, params }),
    { select: (data) => data?.data?.data }
  );

  const hasData = contractsQuery?.data?.length > 0;
  const { checkPermission, isInternalEnv } = useAuth();
  const canDownloadBdx = isInternalEnv && isPoliciesRoute && checkPermissions(ACTIONS.DOWNLOAD_BDX);
  const { showModal } = useModal();

  const handleRowClick = (contractData) => {
    const { status, id } = contractData;
    const canEdit = checkPermission(status)(ACTIONS.UPDATE_SUBMISSION);

    if (canEdit && status === STATUSES.DRAFT) {
      return push(compile(ROUTES.CONTRACT_EDIT)({ productRef, contractId: id }));
    }

    return push(compile(ROUTES.CONTRACT_VIEW)({ productRef, contractId: id }));
  };

  useScrollTop();

  return (
    <>
      <ModalSwitch />

      <div className="pt-16 mt-1.5">
        <NavBar />
      </div>

      {contractsQuery.isLoading && <PageLoader />}

      <div className="bg-white shadow rounded m-8">
        {!contractsQuery.isLoading && hasData && (
          <Table
            headingText={
              (isPoliciesRoute && "Policies") ||
              (isDraftsRoute && "Drafts and Quotes") ||
              (isReferralsRoute && "Referrals")
            }
            headingDecorator={
              canDownloadBdx && (
                <Button
                  className="h-10 -my-2"
                  kind="primary"
                  aria-label="Download Bordereau"
                  onClick={() => showModal(MODALS.DOWNLOAD_BDX, { productRef })}
                >
                  Download Bordereau
                </Button>
              )
            }
            columns={columns[isPoliciesRoute || isDraftsRoute || isReferralsRoute]}
            rows={contractsQuery.data}
            onRowClick={handleRowClick}
          />
        )}

        {!contractsQuery.isLoading && !hasData && (
          <div className="text-center mt-8 py-12">
            <Route path={ROUTES.DRAFTS}>
              <p className="text-2xl font-medium mb-4">Nothing to see here…</p>
              <p className="mb-6">There are no quotes in the system. Click below to get started!</p>
            </Route>

            <Route path={ROUTES.CONTRACTS}>
              <p className="text-2xl font-medium mb-4">It feels a bit empty in here…</p>
              <p className="mb-6">Create a new quote to get started.</p>
            </Route>

            <Route path={ROUTES.REFERRALS}>
              <p className="text-2xl font-medium mb-4">Nothing to see here…</p>
              <p className="mb-6">
                There are currently no quotes within the system. Click below to get started!
              </p>
            </Route>

            {checkPermissions(ACTIONS.CREATE_DRAFT_SUBMISSION) && (
              <Button
                as={Link}
                to={compile(ROUTES.CONTRACT_NEW)({ productRef })}
                kind="primary"
                aria-label="Create new quote"
              >
                Create new quote
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const ContractsPage = () => {
  const { isLoading } = usePrivateRoute();

  useProductCheck();

  if (isLoading) {
    return <PageLoader />;
  }

  return <Contracts />;
};

export default ContractsPage;
