import { compile } from "path-to-regexp";
import React from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import { Redirect, Route, Switch } from "react-router-dom";
import { ROUTES } from "../../../constants";
import ForgotPassword from "../Auth/ForgotPassword";
import Login from "../Auth/Login";
import Logout from "../Auth/Logout";
import PasswordForm from "../Auth/PasswordForm";
import ContractEdit from "../ContractEdit";
import Contracts from "../Contracts";
import ContractView from "../ContractView";
import Dashboard from "../Dashboard";
import Inbox from "../Inbox";
import NotFound from "../NotFound";
import Providers from "./Providers";

const isLocalEnv = process.env.ENVIRONMENT === "local";

const App = () => (
  <Providers>
    <Switch>
      <Route path={ROUTES.LOGOUT} component={Logout} />
      <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
      <Route path={[ROUTES.RESET_PASSWORD, ROUTES.SETUP_PASSWORD]} component={PasswordForm} />
      <Route path={ROUTES.LOGIN} component={Login} />
      <Route path={ROUTES.DASHBOARD} component={Dashboard} />
      <Route path={ROUTES.INBOX} component={Inbox} />
      <Route path={[ROUTES.CONTRACTS, ROUTES.DRAFTS, ROUTES.REFERRALS]} component={Contracts} />
      <Redirect exact from={ROUTES.PRODUCT} to={ROUTES.INBOX} />
      <Route
        path={[
          ROUTES.CONTRACT_EDIT,
          ROUTES.CONTRACT_NEW,
          ROUTES.ENDORSEMENT_EDIT,
          ROUTES.ENDORSEMENT_NEW,
          ROUTES.ENDORSEMENT_BESPOKE_NEW,
          ROUTES.RENEWAL,
        ]}
        component={ContractEdit}
      />
      <Route path={ROUTES.CONTRACT_VIEW} component={ContractView} />
      <Redirect from={ROUTES.CONTRACT} to={ROUTES.CONTRACT_VIEW} />

      <Route path={ROUTES.CONTRACT_NOT_FOUND} component={NotFound} />
      <Route path={ROUTES.NOT_FOUND} component={NotFound} />
      <Redirect
        exact
        from="/"
        to={compile(ROUTES.INBOX)({ productRef: process.env.DEFAULT_PRODUCT_REF })}
      />
      <Route component={NotFound} />
    </Switch>
    {isLocalEnv && <ReactQueryDevtools initialIsOpen={false} />}
  </Providers>
);

export default App;
