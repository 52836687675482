import { compile } from "path-to-regexp";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { ACTIONS, ROUTES, STATUSES } from "../../../constants";
import PageLoader from "../../components/PageLoader";
import { useAuth, useContractQuery, usePrivateRoute } from "../../hooks";
import { useAttachmentQuery } from "../../hooks/useAttachmentQuery";
import { ModalSwitch } from "../../hooks/useModal";
import { useProductCheck } from "../../hooks/useProductCheck";
import { useScrollTop } from "../../hooks/useScrollTop";
import useStickyState from "../../hooks/useStickyState";
import Header from "../Header";
import MessageBox from "../MessageBox";
import SubmissionForm from "./SubmissionForm";

const ContractEdit = () => {
  const { push } = useHistory();
  const { contractId, productRef } = useParams();

  const {
    canCreateEndorsements,
    contractData,
    currentContractData,
    isDraft,
    isEditContractRoute,
    isEditEndorsementRoute,
    isLoading,
    isNewBespokeEndorsementRoute,
    isNewContractRoute,
    isNewEndorsementRoute,
    isRenewalRoute,
    schemaData,
  } = useContractQuery();

  const { uploadAttachment, attachmentsData, isUploading } = useAttachmentQuery();

  const isEditing = isEditContractRoute || isEditEndorsementRoute;
  const { checkPermissions } = useAuth(currentContractData);
  const [isDebuggerVisible, setDebuggerVisible] = useStickyState(false, "formDebugger");
  const [isMessageBoxVisible, setMessageBoxVisible] = useState(false);
  const canEdit = checkPermissions(ACTIONS.UPDATE_SUBMISSION);

  useScrollTop();

  const toggleMessageBox = () => setMessageBoxVisible(!isMessageBoxVisible);

  useEffect(() => {
    if (isEditing && !isLoading && !canEdit) {
      push(compile(ROUTES.CONTRACT_VIEW_DETAILS)({ productRef, contractId }), { isForced: true });
    }
  }, [canEdit, isEditing, isLoading, push, productRef, contractId]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (!isLoading && !checkPermissions(ACTIONS.CREATE_DRAFT_SUBMISSION)) {
    return <Redirect to={compile(ROUTES.CONTRACT_NOT_FOUND)({ productRef })} />;
  }

  return (
    <div className="bg-gray-100">
      <ModalSwitch />

      {isEditing && isMessageBoxVisible && (
        <MessageBox handleClose={toggleMessageBox} contractData={contractData} />
      )}

      <div className="shadow">
        <Header
          attachmentsData={attachmentsData}
          canCreateEndorsements={canCreateEndorsements}
          contractData={currentContractData}
          isDraft={isDraft}
          setDebuggerVisible={setDebuggerVisible}
          toggleMessageBox={toggleMessageBox}
        />
      </div>

      <SubmissionForm
        contractData={contractData}
        currentContractData={currentContractData}
        isDebuggerVisible={isDebuggerVisible}
        isEditEndorsementRoute={isEditEndorsementRoute}
        isEditing={isEditing}
        isNewBespokeEndorsementRoute={isNewBespokeEndorsementRoute}
        isNewEndorsementRoute={isNewEndorsementRoute}
        isRenewalRoute={isRenewalRoute}
        isUploading={isUploading}
        schemaData={schemaData?.properties?.[STATUSES.DRAFT]}
        setDebuggerVisible={setDebuggerVisible}
        showDraftButton={
          isDraft || isNewEndorsementRoute || isNewContractRoute || isNewBespokeEndorsementRoute
        }
        uploadAttachment={uploadAttachment}
      />
    </div>
  );
};

const ContractEditPage = () => {
  const { isLoading } = usePrivateRoute();

  useProductCheck();

  if (isLoading) {
    return <PageLoader />;
  }

  return <ContractEdit />;
};

export default ContractEditPage;
