import React from "react";
import InputSelect from "../InputSelect";

const InputSelectSchema = ({ item, ...props }) => (
  <InputSelect
    {...props}
    options={item.enum.map((value) =>
      typeof value === "object" ? { name: value.name, value: value.value } : { name: value, value }
    )}
  />
);

export default InputSelectSchema;
