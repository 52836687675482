import React from "react";
import DescriptionText from "../DescriptionText";
import ErrorText from "../ErrorText";
import H3 from "../H3";
import H4 from "../H4";

const Section = ({
  ctx,
  formValues,
  item,
  mapChildren,
  onChange,
  ownKey,
  setFormValues,
  validationErrors,
  descriptionText,
  showQuestionId,
}) => {
  const level = ownKey && ownKey.split(".").length + 1;
  const error =
    validationErrors &&
    validationErrors.find((err) => ownKey === err.dataPath.split(".").filter(Boolean).join("."));

  return (
    <div className="bg-white shadow rounded p-6 pb-2 mb-5">
      {level === 2 && (
        <H3 className="mb-4 pb-4 border-b border-gray-400" id={item.key}>
          {item.title}
        </H3>
      )}

      {level === 3 && (
        <H4 className="mb-4" id={item.key}>
          {item.title}
        </H4>
      )}

      {level === 4 && (
        <H4 className="my-8" id={item.key}>
          {item.title}
        </H4>
      )}

      {descriptionText && (
        <DescriptionText className="mt-4 mb-8">{descriptionText}</DescriptionText>
      )}

      {mapChildren({
        ctx,
        formValues,
        onChange,
        parentKey: ownKey,
        parentSchema: item,
        setFormValues,
        validationErrors,
        showQuestionId,
      })}

      {error && error.keyword === "total100" && (
        <ErrorText className="mb-4">{error.message}</ErrorText>
      )}
    </div>
  );
};

export default Section;
