// https://docs.google.com/spreadsheets/d/1XCX3id3FEEiMHmmF2wkucZeW_GAYnvSQJ-1ox_Zrzps/edit#gid=2122343373

import unset from "lodash.unset";
import React from "react";
import { ACTIONS } from "../../../../constants";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import InputCurrency from "../../../components/InputCurrency";
import InputPercentage from "../../../components/InputPercentage";
import InputRadioGroup from "../../../components/InputRadioGroup";
import useForm from "../../../hooks/useForm";
import { useMutateContractQuery } from "../../../hooks/useMutateContractQuery";

const ReferralDecision = ({ className, contractData }) => {
  const { updateContract, contractId, productRef, isUpdating } = useMutateContractQuery({});

  const { getFieldProps, formValues, onSubmit } = useForm({
    initialState: {},
    onBeforeChange: (clonedFormValues, name) => {
      if (name === "premium_type") {
        unset(clonedFormValues, "adjustableRate");
        unset(clonedFormValues, "premiumTotal");
      }
    },
    onSubmit: (values) => {
      updateContract({
        productRef,
        contractId,
        data: {
          type: ACTIONS.PROVIDE_COMMERCIAL_PRICE,
          payload:
            formValues.premium_type !== "Adjustable premium"
              ? values
              : {
                  adjustableRate: values.adjustableRate,
                  premiumTotal: {
                    amount:
                      contractData?.submission?.base?.insured_salary?.amount *
                      (values?.adjustableRate / 100),
                  },
                },
        },
      });
    },
  });

  const adjustablePremium =
    contractData?.submission?.base?.insured_salary?.amount * (formValues?.adjustableRate / 100);

  return (
    <Card headingText="Next steps" className={className}>
      <div className="p-6">
        <Form onSubmit={onSubmit} id="decisionForm">
          <InputRadioGroup
            className="mb-8"
            isRequired
            labelText="Premium"
            options={["Flat premium", "Adjustable premium"]}
            {...getFieldProps("premium_type")}
          />

          {formValues.premium_type === "Flat premium" && (
            <>
              <InputCurrency
                className="mb-8"
                isRequired
                labelText="Total Premium"
                {...getFieldProps("premiumTotal")}
              />
            </>
          )}

          {formValues.premium_type === "Adjustable premium" && (
            <>
              <InputCurrency
                key={contractData?.submission?.base?.insured_salary}
                className="mb-8"
                isRequired
                labelText="Annual salary"
                isReadOnly
                name="insured_salary"
                value={contractData?.submission?.base?.insured_salary}
              />

              <InputPercentage
                className="mb-8"
                isRequired
                labelText="Rate"
                isAllowed={({ floatValue, value }) => value === "" || floatValue <= 100}
                {...getFieldProps("adjustableRate")}
              />

              <InputCurrency
                key={formValues?.insured_salary?.amount + formValues?.adjustableRate}
                className="mb-8"
                isRequired
                isReadOnly
                name="premiumTotal"
                labelText="Total Premium"
                value={{ amount: Number.isNaN(adjustablePremium) ? 0 : adjustablePremium }}
              />
            </>
          )}

          <div className="flex">
            <Button className="mr-4" isDisabled={isUpdating} kind="primary" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Card>
  );
};

export default ReferralDecision;
