import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import React from "react";
import Button from "../Button";

const Repeater = ({
  className,
  formValues,
  value,
  id,
  item,
  mapChildren,
  onChange,
  ownKey,
  setFormValues,
  validationErrors,
}) => {
  const hasInitialValue = value && Array.isArray(value) && value.length > 0;
  const initialItems = hasInitialValue ? value : [""];

  const handleAdd = () => {
    const cloned = cloneDeep(formValues);

    set(cloned, ownKey, [...initialItems, ""]);
    setFormValues(cloned);
  };

  const handleRemove = (itemIndex) => () => {
    const cloned = cloneDeep(formValues);
    const filtered = initialItems.filter((v, index) => index !== itemIndex);

    set(cloned, ownKey, filtered);
    setFormValues(cloned);
  };

  const schemaItem =
    item.items.type === "object" ? item.items : { properties: { [id]: item.items } };

  return (
    <div className={className}>
      {initialItems.map((lineItem, index) => {
        const key = `${ownKey}[${index}]`;

        return (
          <div className="flex" key={index.toString()}>
            {mapChildren({
              parentSchema: schemaItem,
              formValues,
              parentKey: key,
              onChange,
              setFormValues,
              useParentKey: true,
              validationErrors,
            })}

            {initialItems.length > 1 && (
              <Button kind="danger-secondary" onClick={handleRemove(index)} className="mt-8 ml-8">
                Remove
              </Button>
            )}
          </div>
        );
      })}

      <Button onClick={handleAdd}>Add</Button>
    </div>
  );
};

export default Repeater;
