export const getColorFromId = (activeUserId) => {
  const colors = [
    "red",
    "grape",
    "violet",
    "indigo",
    "blue",
    "cyan",
    "teal",
    "green",
    "orange",
    "pink",
  ];
  const index = [...activeUserId]
    .reduce((prev, next) => next.charCodeAt(0) + prev, 0)
    .toString()
    .split("")
    .pop();

  return `bg-${colors[index]}-500`;
};
