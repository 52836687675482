import clsx from "clsx";
import React from "react";
import { QUOTE_STATUSES, QUOTE_TYPES, STATUSES, STATUS_TEXT_MAP } from "../../constants";

const statusMap = {
  [QUOTE_STATUSES.ARCHIVED]: "text-gray-900 bg-gray-200",
  [QUOTE_STATUSES.SUBMITTED]: "text-orange-900 bg-orange-200",
  [QUOTE_TYPES.DEFAULT]: "text-blue-900 bg-blue-200",
  [STATUSES.APPLIED]: "text-green-900 bg-green-200",
  [STATUSES.BOUND]: "text-green-900 bg-green-200",
  [STATUSES.CANCELLED]: "text-gray-900 bg-gray-200",
  [STATUSES.DECLINED]: "text-orange-900 bg-orange-200",
  [STATUSES.DRAFT]: "text-orange-900 bg-orange-200",
  [STATUSES.EXPIRED]: "text-gray-900 bg-gray-200",
  [STATUSES.IDLE]: "text-gray-900 bg-gray-200",
  [STATUSES.IN_PROGRESS]: "text-orange-900 bg-orange-200",
  [STATUSES.QUOTED]: "text-orange-900 bg-orange-200",
  [STATUSES.REFERRED_QUOTED]: "text-orange-900 bg-orange-200",
  [STATUSES.REFERRED]: "text-orange-900 bg-orange-200",
  [STATUSES.REJECTED_NOT_PROGRESSED]: "text-gray-900 bg-gray-200",
  [STATUSES.REJECTED_NOT_TAKEN_UP]: "text-gray-900 bg-gray-200",
};

const ahStatusMap = {
  [QUOTE_STATUSES.ARCHIVED]: "text-gray-900 bg-gray-200",
  [QUOTE_STATUSES.SUBMITTED]: "text-green-1000 bg-green-100",
  [QUOTE_TYPES.DEFAULT]: "text-blue-900 bg-blue-200",
  [STATUSES.APPLIED]: "text-blue-1000 bg-blue-100",
  [STATUSES.BOUND]: "text-blue-1000 bg-blue-100",
  [STATUSES.CANCELLED]: "text-red-1000 bg-red-100",
  [STATUSES.DECLINED]: "text-red-1000 bg-red-100",
  [STATUSES.DRAFT]: "text-gray-900 bg-gray-200",
  [STATUSES.EXPIRED]: "text-gray-900 bg-gray-200",
  [STATUSES.IDLE]: "text-gray-1000 bg-gray-100",
  [STATUSES.IN_PROGRESS]: "text-green-1000 bg-green-100",
  [STATUSES.QUOTED]: "text-green-1000 bg-green-100",
  [STATUSES.REFERRED_QUOTED]: "text-green-1000 bg-green-100",
  [STATUSES.REFERRED]: "text-orange-1000 bg-orange-100",
  [STATUSES.REJECTED_NOT_PROGRESSED]: "text-red-1000 bg-red-100",
  [STATUSES.REJECTED_NOT_TAKEN_UP]: "text-red-1000 bg-red-100",
};

const colorMap = process.env.DEFAULT_PRODUCT_REF === "ah" ? ahStatusMap : statusMap;

const kindsMap = {
  normal: "text-sm h-12 px-3 font-medium rounded",
  small: "text-xs h-5 px-2 font-medium rounded-full",
};

const Status = ({ status, statusText, className, kind }) => {
  const text = statusText || STATUS_TEXT_MAP[status] || status;

  return (
    <div
      className={clsx(
        "inline-flex items-center justify-center ",
        kindsMap[kind],
        colorMap[status],
        className
      )}
    >
      {text}
    </div>
  );
};

Status.defaultProps = {
  kind: "normal",
};

export default Status;
