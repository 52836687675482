import PropTypes from "prop-types";
import React from "react";

const AuthLayout = ({ children }) => (
  <div
    className="h-screen flex w-full bg-center bg-cover items-center"
    style={{
      backgroundImage:
        (process.env.DEFAULT_PRODUCT_REF === "ah" && "url(/bg-convex-rfp-optimized.jpg)") ||
        "url(/Convex_DUW_MEL_Flex_1_1080.jpg)",
    }}
  >
    <div className="flex pl-24 w-screen">
      <div className="bg-white w-full max-w-96 xl:max-w-128 shadow-xl flex flex-col overflow-y-auto pt-8 rounded">
        <div className="flex justify-center mb-10">
          {process.env.DEFAULT_PRODUCT_REF !== "ah" && (
            <img src="/logo-colour.png" className="w-56 self-center" alt="Convex Logo" />
          )}

          {process.env.DEFAULT_PRODUCT_REF === "ah" && (
            <img
              src="/artificial-logo-black.svg"
              className="w-48 self-center"
              alt="Artificial Logo"
            />
          )}
        </div>
        <div className="px-10 mt-8 flex-auto pb-10">{children}</div>
      </div>

      <div className="flex flex-col self-center pt-10 ml-16">
        {process.env.DEFAULT_PRODUCT_REF === "ah" && (
          <>
            <h1
              className="text-gray-900 font-semibold text-6xl leading-tight"
              style={{ textShadow: "rgb(255 255 255 / 50%) 1px 1px 1px" }}
            >
              Tailored cover <br />
              for your clients.
            </h1>
            <p
              className="text-gray-900 text-xl mt-8"
              style={{ textShadow: "rgb(255 255 255 / 50%) 1px 1px 1px" }}
            >
              The easiest way to quote and bind <br />
              Accident & Health insurance.
            </p>
          </>
        )}

        {process.env.DEFAULT_PRODUCT_REF !== "ah" && (
          <>
            <h1
              className="text-white font-semibold text-6xl leading-tight"
              style={{ textShadow: "rgb(74 74 74) 1px 1px 1px" }}
            >
              Cover your <br />
              clients deserve.
            </h1>

            <p
              className="text-gray-100 text-xl mt-8"
              style={{ textShadow: "rgb(74 74 74) 1px 1px 1px" }}
            >
              The easiest way to quote and bind <br />
              Maritime Employers&apos; Liability insurance
            </p>
          </>
        )}
      </div>
    </div>
  </div>
);

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthLayout;
