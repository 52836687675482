/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import confetti from "canvas-confetti";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import Button from "../components/Button";
import H2 from "../components/H2";
import Modal from "../components/Modal";

const Svg = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" className={clsx("w-16", className)}>
    <circle cx="26" cy="26" r="25" fill="#339af0" />
    <path stroke="#fff" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
  </svg>
);

const BindQuoteSuccessModal = ({ handleClose }) => {
  const ref = useRef();

  useEffect(() => {
    const myConfetti = confetti.create(ref.current, { resize: true, useWorker: true });

    myConfetti({ particleCount: 100, spread: 160 });
  }, []);

  return (
    <Modal handleClose={handleClose}>
      <div className="relative" onClick={handleClose}>
        <canvas ref={ref} className="w-full" />
        <div className="absolute inset-0">
          <div className="flex items-center justify-center">
            <Svg className="mt-12 inline-flex" />
          </div>

          <H2 className="text-center mb-4 mt-10">Success!</H2>

          <p className="text-center">Your quote is bound.</p>

          <div className="flex items-center justify-center mt-12">
            <Button onClick={() => handleClose()}>Return to policy details</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

BindQuoteSuccessModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default BindQuoteSuccessModal;
