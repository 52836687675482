import Auth from "@aws-amplify/auth";
import * as Sentry from "@sentry/browser";
import React from "react";
import { render } from "react-dom";
import App from "./modules/App";
import "./styles/index.css";

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.ENVIRONMENT,
  release: `convex@${process.env.npm_package_version}`,
});

Auth.configure({
  identityPoolId: process.env.COGNITO_IDENTITY_POOL_ID,
  identityPoolRegion: "eu-west-1",
  region: "eu-west-1",
  userPoolId: process.env.COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.COGNITO_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.COGNITO_DOMAIN,
    scope: ["email", "openid", "profile"],
    redirectSignIn: process.env.COGNITO_REDIRECT,
    redirectSignOut: process.env.COGNITO_REDIRECT,
    responseType: "code",
  },
});

render(<App />, document.getElementById("root"));
