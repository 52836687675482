import Auth from "@aws-amplify/auth";
import axios from "axios";
import get from "lodash.get";
import { ENDPOINTS } from "../../constants";
import { DMS } from "../../constants/dms";

export const fetch = axios.create({ baseURL: process.env.API_URL });
export const fetchIdentity = axios.create({ baseURL: process.env.IDENTITY_API_URL });

const requestInterceptor = async (config) => {
  const amplifyUser = await Auth.currentAuthenticatedUser();
  const token = get(amplifyUser, "signInUserSession.idToken.jwtToken");

  return { ...config, headers: { ...config.headers, Authorization: token } };
};

const responseInterceptor = async (error) => {
  if (get(error, "response.status") === 401) {
    const amplifyUser = await Auth.currentAuthenticatedUser();
    const token = get(amplifyUser, "signInUserSession.idToken.jwtToken");
    const prevConfig = get(error, "response.config");

    return axios({ ...prevConfig, headers: { ...prevConfig.headers, Authorization: token } });
  }

  return Promise.reject(error);
};

fetch.interceptors.request.use(requestInterceptor);
fetch.interceptors.response.use(null, responseInterceptor);

fetchIdentity.interceptors.request.use(requestInterceptor);
fetchIdentity.interceptors.response.use(null, responseInterceptor);

/* Identity */

export const getUsers = () => fetchIdentity({ method: "get", url: ENDPOINTS.USERS });

export const getTenants = () => fetchIdentity({ method: "get", url: ENDPOINTS.TENANTS });

export const getProducingBrokers = () =>
  fetchIdentity({ method: "get", url: ENDPOINTS.PRODUCING_BROKERS });

/* Contracts */

export const getContract = ({ queryKey }) => {
  const [, { productRef, contractId }] = queryKey;

  return fetch({ method: "get", url: ENDPOINTS.CONTRACTS({ productRef, contractId }) });
};

export const getContracts = ({ productRef, params }) =>
  fetch({ method: "get", url: ENDPOINTS.CONTRACTS({ productRef }), params });

export const createContract = ({ productRef, data }) =>
  fetch({ method: "post", url: ENDPOINTS.CONTRACTS({ productRef }), data });

export const updateContract = ({ productRef, contractId, data }) =>
  fetch({ method: "put", url: ENDPOINTS.CONTRACTS({ productRef, contractId }), data });

/* Bdx */

export const createBdx = ({ productRef, data }) =>
  fetch({ method: "post", url: ENDPOINTS.BDX({ productRef }), data });

/* Quotes */

export const getQuote = ({ queryKey }) => {
  const [, { productRef, contractId, quoteId }] = queryKey;

  return fetch({ method: "get", url: ENDPOINTS.QUOTES({ productRef, contractId, quoteId }) });
};

export const updateQuote = ({ productRef, contractId, quoteId, data }) =>
  fetch({ method: "put", url: ENDPOINTS.QUOTES({ productRef, contractId, quoteId }), data });

/* Endorsements */

export const createEndorsement = ({ productRef, contractId, data }) =>
  fetch({ method: "post", url: ENDPOINTS.ENDORSEMENTS({ productRef, contractId }), data });

export const getEndorsement = ({ queryKey }) => {
  const [, { productRef, contractId, endorsementId }] = queryKey;

  return fetch({
    method: "get",
    url: ENDPOINTS.ENDORSEMENT({ productRef, contractId, endorsementId }),
  });
};

export const getEndorsements = ({ queryKey }) => {
  const [, { productRef, contractId }] = queryKey;

  return fetch({ method: "get", url: ENDPOINTS.ENDORSEMENTS({ productRef, contractId }) });
};

export const updateEndorsement = ({ productRef, contractId, endorsementId, data }) =>
  fetch({
    method: "put",
    url: ENDPOINTS.ENDORSEMENT({ productRef, contractId, endorsementId }),
    data,
  });

/* Endorsement quotes */

export const getEndorsementQuote = ({ queryKey }) => {
  const [, { productRef, contractId, endorsementId, quoteId }] = queryKey;

  return fetch({
    method: "get",
    url: ENDPOINTS.ENDORSEMENT_QUOTES({ productRef, contractId, endorsementId, quoteId }),
  });
};

export const updateEndorsementQuote = ({ productRef, contractId, endorsementId, quoteId, data }) =>
  fetch({
    method: "put",
    url: ENDPOINTS.ENDORSEMENT_QUOTES({ productRef, contractId, endorsementId, quoteId }),
    data,
  });

/* Clauses */

export const getClauses = ({ queryKey }) => {
  const [, { productRef }] = queryKey;

  return fetch({ method: "get", url: ENDPOINTS.CLAUSES({ productRef }) });
};

export const createClause = ({ productRef, data }) =>
  fetch({ method: "post", url: ENDPOINTS.CLAUSES({ productRef }), data });

export const getContractClauses = ({ queryKey }) => {
  const [, { productRef, contractId, endorsementId }] = queryKey;

  return fetch({
    method: "get",
    url: endorsementId
      ? ENDPOINTS.ENDORSEMENT_CLAUSES({ productRef, contractId, endorsementId })
      : ENDPOINTS.CONTRACT_CLAUSES({ productRef, contractId }),
  });
};

export const createContractClause = ({ productRef, contractId, data, endorsementId }) =>
  fetch({
    method: "post",
    url: endorsementId
      ? ENDPOINTS.ENDORSEMENT_CLAUSES({ productRef, contractId, endorsementId })
      : ENDPOINTS.CONTRACT_CLAUSES({ productRef, contractId }),
    data,
  });

export const updateContractClause = ({ productRef, contractId, clauseId, data, endorsementId }) =>
  fetch({
    method: "put",
    url: endorsementId
      ? ENDPOINTS.ENDORSEMENT_CLAUSE({ productRef, contractId, clauseId, endorsementId })
      : ENDPOINTS.CONTRACT_CLAUSE({ productRef, contractId, clauseId }),
    data,
  });

export const deleteContractClause = ({ productRef, contractId, clauseId, endorsementId }) =>
  fetch({
    method: "delete",
    url: endorsementId
      ? ENDPOINTS.ENDORSEMENT_CLAUSE({ productRef, contractId, clauseId, endorsementId })
      : ENDPOINTS.CONTRACT_CLAUSE({ productRef, clauseId, contractId }),
  });

/*  Snapshots */

export const getSnapshots = ({ queryKey }) => {
  const [, { productRef, contractId }] = queryKey;

  return fetch({ method: "get", url: ENDPOINTS.SNAPSHOTS({ productRef, contractId }) });
};

/*  Audit */

export const getAudit = ({ queryKey }) => {
  const [, { productRef, contractId }] = queryKey;

  return fetch({ method: "get", url: ENDPOINTS.AUDIT({ productRef, contractId }) });
};

/* Messages */

export const getMessages = ({ queryKey }) => {
  const [, { productRef, contractId }, params] = queryKey;

  return fetch({ method: "get", url: ENDPOINTS.MESSAGES({ productRef, contractId }), params });
};

export const createMessage = ({ productRef, contractId, data }) =>
  fetch({ method: "post", url: ENDPOINTS.MESSAGES({ productRef, contractId }), data });

/* Connections */

export const getConnections = ({ queryKey }) => {
  const [, { productRef, contractId, data }] = queryKey;

  return fetch({ method: "put", url: ENDPOINTS.CONNECTIONS({ productRef, contractId }), data });
};

export const updateConnections = ({ productRef, contractId, data }) =>
  fetch({ method: "post", url: ENDPOINTS.CONNECTIONS({ productRef, contractId }), data });

/* Products */

export const getProduct = ({ queryKey }) => {
  const [, { productRef }] = queryKey;

  return fetch({ method: "get", url: ENDPOINTS.PRODUCTS({ productRef }) });
};

export const getProducts = () => fetch({ method: "get", url: ENDPOINTS.PRODUCTS() });

export const createProduct = ({ data }) =>
  fetch({ method: "post", url: ENDPOINTS.PRODUCTS(), data });

export const updateProduct = ({ data, productRef }) =>
  fetch({ method: "put", url: ENDPOINTS.PRODUCTS({ productRef }), data });

/* Schemas */

export const getSchema = ({ queryKey }) => {
  const [, { schemaId }] = queryKey;

  return fetch({ method: "get", url: ENDPOINTS.SCHEMAS({ schemaId }) });
};

export const getSchemas = () => fetch({ method: "get", url: ENDPOINTS.SCHEMAS() });

export const createSchema = ({ data }) => fetch({ method: "post", url: ENDPOINTS.SCHEMAS(), data });

export const updateSchema = ({ data, schemaId }) =>
  fetch({ method: "put", url: ENDPOINTS.SCHEMAS({ schemaId }), data });

/* Checks */

export const checkReferrals = ({ data }) =>
  fetch({ method: "post", url: ENDPOINTS.REFERRALS_CHECK, data });

export const checkRater = ({ data }) => fetch({ method: "post", url: ENDPOINTS.RATER_CHECK, data });

/* DMS */

export const uploadAttachment = ({ productRef, contractId, endorsementId, data }) =>
  fetch({
    data,
    method: "put",
    url: endorsementId
      ? ENDPOINTS.ENDO_DOCUMENTS({ productRef, contractId, endorsementId })
      : ENDPOINTS.DOCUMENTS({ productRef, contractId }),
  });

export const getAttachments = ({ queryKey }) => {
  const [, { productRef, contractId, endorsementId }] = queryKey;

  return fetch({
    method: "get",
    params: { type: "attachment" },
    url: endorsementId
      ? ENDPOINTS.ENDO_DOCUMENTS({ productRef, contractId, endorsementId })
      : ENDPOINTS.DOCUMENTS({ productRef, contractId }),
  });
};

export const getAttachmentLink = ({ productRef, contractId, endorsementId, documentId }) =>
  fetch({
    method: "get",
    url: endorsementId
      ? ENDPOINTS.ENDO_DOCUMENT({ productRef, contractId, endorsementId, documentId })
      : ENDPOINTS.DOCUMENT({ productRef, contractId, documentId }),
  });

export const applyEndoAttachments = ({ productRef, contractId, endorsementId }) =>
  fetch({
    method: "patch",
    url: ENDPOINTS.ENDO_DOCUMENTS_APPLY({ productRef, contractId, endorsementId }),
  });

export const getDocuments = async ({ productRef, contractId, endorsementId, predicate }) => {
  try {
    const res = await fetch({
      method: "get",
      params: { type: DMS.UNIQUE_DOCUMENT_TYPES },
      url: endorsementId
        ? ENDPOINTS.ENDO_DOCUMENTS({ productRef, contractId, endorsementId })
        : ENDPOINTS.DOCUMENTS({ productRef, contractId }),
    });

    const documentsData = res?.data?.data || [];

    if (predicate && !predicate(documentsData)) {
      throw new Error();
    }

    return documentsData;
  } catch (error) {
    throw new Error(error);
  }
};
