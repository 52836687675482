/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ACTIONS, MODALS, QUOTE_STATUSES, QUOTE_TYPES, STATUSES } from "../../../constants";
import { currencyFormatter } from "../../../formatters";
import { stringifyParams } from "../../../helpers/stringifyParams";
import Button from "../../components/Button";
import H3 from "../../components/H3";
import Status from "../../components/Status";
import { useAuth, useModal } from "../../hooks";
import { useAttachmentQuery } from "../../hooks/useAttachmentQuery";

const bySubmitted = (quoteData) => quoteData.status === QUOTE_STATUSES.SUBMITTED;

const AHColumns = [
  {
    displayName: "Ref",
    id: "type",
    path: "ref",
  },
  {
    displayName: "Total premium",
    formatter: (amount) => currencyFormatter({ amount }),
    path: "rates.premiumTotal",
  },
];

const AHQuoteRow = ({
  contractData,
  handleBindClick,
  handleQuoteClick,
  isCurrent,
  isEndorsement,
  isExpandable,
  isExpanded,
  quoteData,
}) => {
  const isSubmitted = quoteData.status === QUOTE_STATUSES.SUBMITTED;
  const { checkPermissions } = useAuth(contractData);

  return (
    <>
      <tr
        className={clsx("border-b border-gray-300 last:border-b-0 h-16 transition")}
        onClick={isExpandable ? handleQuoteClick(quoteData.id, isExpanded) : undefined}
      >
        <td className="pl-6">{quoteData.ref}</td>
        {!isEndorsement && (
          <td>
            {currencyFormatter({ amount: quoteData?.rates?.premiumTotal })}{" "}
            {quoteData?.rates?.adjustableRate || quoteData?.rates?.adjustableRate === 0
              ? `adjustable at ${quoteData?.rates?.adjustableRate}% of salary`
              : ""}
          </td>
        )}
        {isEndorsement && (
          <td>{currencyFormatter({ amount: Math.abs(quoteData?.rates?.aprp) })}</td>
        )}
        <td>
          <Status kind="small" status={quoteData.status} />
        </td>
        <td className="w-24">
          {isSubmitted && isCurrent && checkPermissions(ACTIONS.BIND_QUOTE) && (
            <Button kind="primary" className="h-10 px-6" onClick={handleBindClick(quoteData.id)}>
              Bind
            </Button>
          )}
        </td>
      </tr>

      {isExpanded && isExpandable && (
        <tr>
          <td colSpan="6">
            <div>
              <table className="w-full">
                <tbody>
                  {quoteData?.rates?.ptdPremiumAnnual && (
                    <tr className="border-b border-gray-300 h-16">
                      <td className="pl-6 w-1/2">PTD Annual Premium</td>
                      <td className="pr-6">
                        {currencyFormatter({ amount: quoteData.rates.ptdPremiumAnnual })}
                      </td>
                    </tr>
                  )}

                  {quoteData?.rates?.ptdPremiumTotal && (
                    <tr className="border-b border-gray-300 h-16">
                      <td className="pl-6 w-1/2">PTD Total Premium</td>
                      <td className="pr-6">
                        {currencyFormatter({ amount: quoteData.rates.ptdPremiumTotal })}
                      </td>
                    </tr>
                  )}

                  {quoteData?.rates?.ttdPremiumAnnual && (
                    <tr className="border-b border-gray-300 h-16">
                      <td className="pl-6 w-1/2">TTD Annual Premium</td>
                      <td className="pr-6">
                        {currencyFormatter({ amount: quoteData.rates.ttdPremiumAnnual })}
                      </td>
                    </tr>
                  )}

                  {quoteData?.rates?.ttdPremiumTotal && (
                    <tr className="border-b border-gray-300 h-16">
                      <td className="pl-6 w-1/2">TTD Total Premium</td>
                      <td className="pr-6">
                        {currencyFormatter({ amount: quoteData.rates.ttdPremiumTotal })}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

const AHQuoteCard = ({
  contractData,
  expandAll,
  heading,
  headingText,
  isCurrent,
  isEndorsement,
  quotesData,
}) => {
  const { showModal } = useModal();
  const { location, push } = useHistory();
  const { applyEndoAttachment } = useAttachmentQuery();
  const endorsementId = isEndorsement ? contractData.id : undefined;
  const { productRef, contractId } = useParams();

  const defaultQuote = quotesData.find((quote) => quote.type === QUOTE_TYPES.DEFAULT);
  const commercialQuote = quotesData.find((quote) => quote.type === QUOTE_TYPES.COMMERCIAL);

  const areSubmitted = quotesData.every(bySubmitted);
  const allIds = quotesData.map((quoteData) => quoteData.id);
  const initialIds = expandAll ? allIds : [areSubmitted && commercialQuote?.id];
  const [selectedIds, setSelectedIds] = useState(initialIds);

  const rest = quotesData.filter((quote) => quote.type !== QUOTE_TYPES.DEFAULT);

  const sortedQuotesData = defaultQuote ? [defaultQuote, ...rest] : rest;
  const firstQuote = quotesData[0];

  const handleQuoteClick = (quoteId, expanded) => (event) => {
    const nextIds = expanded
      ? selectedIds.filter((id) => id !== quoteId)
      : [...selectedIds, quoteId];

    event.stopPropagation();

    return setSelectedIds(nextIds);
  };

  const handleBindSuccess = async (nextContractData) => {
    if (nextContractData.status === STATUSES.APPLIED) {
      await applyEndoAttachment({ productRef, contractId, endorsementId });
    }

    if (nextContractData.status === STATUSES.BOUND) {
      return showModal(MODALS.BIND_QUOTE_SUCCESS);
    }

    return false;
  };

  const handleBindClick = (quoteId) => (event) => {
    event.stopPropagation();
    push({ pathname: location.pathname, search: stringifyParams({ quoteId, endorsementId }) });

    showModal(MODALS.BIND_QUOTE, {
      callback: handleBindSuccess,
    });
  };

  return (
    <div className="bg-white shadow rounded mb-4">
      {heading}
      {headingText && <H3 className="mb-4 p-4">{headingText}</H3>}
      <div>
        <table className="w-full">
          <thead className="text-left text-sm ">
            <tr className="mb-3 ">
              <th className="font-normal pl-6">
                <div className="mb-3">Ref</div>
              </th>
              {!isEndorsement && (
                <th className="font-normal">
                  <div className="mb-3">Total premium</div>
                </th>
              )}
              {isEndorsement && (
                <th className="font-normal">
                  <div className="mb-3">
                    {firstQuote?.rates?.aprp >= 0 ? "Amount due" : "Amount owed"}
                  </div>
                </th>
              )}
              <th />
              <th />
              <th className="w-10" />
            </tr>
          </thead>
          <tbody>
            {sortedQuotesData.map((quoteData, index) => (
              <AHQuoteRow
                columns={AHColumns}
                contractData={contractData}
                handleBindClick={handleBindClick}
                handleQuoteClick={handleQuoteClick}
                isCurrent={isCurrent}
                isEndorsement={isEndorsement}
                isExpandable={!isEndorsement}
                isExpanded={selectedIds.includes(quoteData.id)}
                key={quoteData.id}
                quoteData={quoteData}
                quotesData={quotesData}
                rowIndex={index}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

AHQuoteCard.propTypes = {
  quotesData: PropTypes.array,
};

AHQuoteCard.defaultProps = {
  quotesData: [],
};

export default AHQuoteCard;
