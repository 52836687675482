import get from "lodash.get";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { parseParams } from "../../helpers/parseParams";
import * as api from "../api";

export const useQuoteQuery = () => {
  const queryClient = useQueryClient();

  const { location } = useHistory();
  const params = useParams();
  const queryParams = parseParams(location.search);
  const { productRef, contractId } = params;
  const { quoteId } = queryParams;
  const endorsementId = queryParams.endorsementId || params.endorsementId;

  const { mutateAsync: updateQuote, ...updateQuoteQuery } = useMutation(api.updateQuote, {
    onSuccess: (data) => {
      queryClient.setQueryData(["contract", { productRef, contractId }], data);
      queryClient.invalidateQueries("contracts", { productRef });
      queryClient.invalidateQueries("quote", { productRef, contractId });
      queryClient.invalidateQueries("contractClauses");
      setTimeout(() => {
        queryClient.resetQueries("documents", { productRef, contractId });
      }, 2000);
    },
  });

  const { mutateAsync: updateEndoQuote, ...updateEndoQuoteQuery } = useMutation(
    api.updateEndorsementQuote,
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["endorsement", { productRef, contractId, endorsementId }], data);
        queryClient.invalidateQueries("audit", { productRef, contractId });
        queryClient.invalidateQueries("contract", { productRef, contractId });
        queryClient.invalidateQueries("contractClauses", { productRef, contractId });
        queryClient.invalidateQueries("contracts", { productRef });
        queryClient.invalidateQueries("endorsements", { productRef, contractId });
        queryClient.invalidateQueries("endoQuote", {
          productRef,
          contractId,
          endorsementId,
          quoteId,
        });
        setTimeout(() => {
          queryClient.resetQueries("documents", { productRef, contractId, endorsementId });
        }, 2000);
      },
    }
  );

  const productQuery = useQuery(["product", { productRef }], api.getProduct);
  const productData = get(productQuery, "data.data.data", {});

  const schemaId = get(productData, "schemaId");
  const schemaQuery = useQuery(["schema", { schemaId }], api.getSchema, {
    enabled: Boolean(schemaId),
  });
  const schemaData = get(schemaQuery, ["data", "data", "data", "payload"]);

  const quoteKey = ["quote", { productRef, contractId, quoteId }];
  const quoteQuery = useQuery(quoteKey, api.getQuote, { enabled: Boolean(!endorsementId) });
  const quoteData = get(quoteQuery, "data.data.data", {});

  const key = ["endoQuote", { productRef, contractId, endorsementId, quoteId }];
  const endorsementQuoteQuery = useQuery(key, api.getEndorsementQuote, {
    enabled: Boolean(endorsementId),
  });
  const endorsementQuoteData = get(endorsementQuoteQuery, "data.data.data", {});

  const isLoading =
    quoteQuery.isLoading ||
    schemaQuery.isLoading ||
    productQuery.isLoading ||
    endorsementQuoteQuery.isLoading;

  const isUpdating = updateQuoteQuery.isLoading || updateEndoQuoteQuery.isLoading;

  return {
    contractId,
    endorsementId,
    endorsementQuoteData,
    isLoading,
    isUpdating,
    productData,
    productRef,
    quoteData: endorsementId ? endorsementQuoteData : quoteData,
    quoteId,
    schemaData,
    updateQuote: endorsementId ? updateEndoQuote : updateQuote,
  };
};

export default useQuoteQuery;
