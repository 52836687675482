import clsx from "clsx";
import get from "lodash.get";
import sortBy from "lodash.sortby";
import React from "react";
import formatErrors from "./formatErrors";
import { getComponentType } from "./getComponentType";

export const MakeComponent = ({
  ctx,
  formValues,
  isReadOnly,
  item,
  mapChildren,
  noErrorLabel,
  onBlur,
  onChange,
  parentKey,
  parentSchema,
  setFormValues,
  showQuestionId,
  useParentKey,
  validationErrors,
}) => {
  const Component = getComponentType(item);
  const isHidden = get(item, "ui:hidden");
  const isInternalEnv = ["local", "development"].includes(process.env.ENVIRONMENT);
  let ownKey = parentKey === "" ? item.key : [parentKey, item.key].join(".");

  if (useParentKey) {
    ownKey = parentKey;
  }

  if ("ui:debugger" in item && !isInternalEnv) {
    return false;
  }

  return (
    <Component
      className={clsx("mb-8", isHidden && "hidden")}
      ctx={ctx}
      descriptionText={get(item, "ui:description")}
      errors={validationErrors?.find((error) => error.path === ownKey)?.message}
      formValues={formValues}
      id={item.key}
      isReadOnly={isReadOnly || get(item, "ui:component:readonly")}
      isRequired={get(parentSchema, ["required"], []).includes(item.key)}
      item={item}
      key={item.key}
      keyFormValues={formValues[parentKey]}
      labelText={showQuestionId ? `${item.qid}. ${item.title}` : item.title || ownKey}
      MakeComponent={MakeComponent}
      mapChildren={mapChildren}
      name={ownKey}
      noErrorLabel={noErrorLabel}
      onBlur={onBlur}
      onChange={onChange}
      options={"enum" in item ? item.enum : undefined}
      ownKey={ownKey}
      parentKey={parentKey}
      parentSchema={parentSchema}
      setFormValues={setFormValues}
      showQuestionId={showQuestionId}
      validationErrors={validationErrors}
      value={get(formValues, ownKey)}
    />
  );
};

const mapChildren = ({
  ctx,
  formValues = {},
  isReadOnly = false,
  noErrorLabel,
  onBlur,
  onChange,
  parentKey,
  parentSchema,
  setFormValues,
  useParentKey,
  validationErrors,
  showQuestionId,
}) => {
  const mappedProperties = Object.keys(parentSchema.properties).map((key) => ({
    key,
    ...parentSchema.properties[key],
  }));
  const sortedProperties = sortBy(mappedProperties, (c) => c.qid);

  return sortedProperties.map((item) => (
    <MakeComponent
      ctx={ctx}
      formValues={formValues}
      isReadOnly={isReadOnly}
      item={item}
      key={item.key}
      mapChildren={mapChildren}
      noErrorLabel={noErrorLabel}
      onBlur={onBlur}
      onChange={onChange}
      parentKey={parentKey}
      parentSchema={parentSchema}
      setFormValues={setFormValues}
      showQuestionId={showQuestionId}
      useParentKey={useParentKey}
      validationErrors={formatErrors(validationErrors)}
    />
  ));
};

export default mapChildren;
