import React from "react";
import { ROUTES, STATUSES } from "../../../constants";
import { dateFormatter, dateTimeFormatter } from "../../../formatters";
import Status from "../../components/Status";

export const filters = {
  [ROUTES.DRAFTS]: [
    STATUSES.DECLINED,
    STATUSES.DRAFT,
    STATUSES.QUOTED,
    STATUSES.REFERRED_QUOTED,
    STATUSES.IN_PROGRESS,
    STATUSES.REFERRED,
    STATUSES.REJECTED_NOT_PROGRESSED,
    STATUSES.REJECTED_NOT_TAKEN_UP,
    STATUSES.EXPIRED,
  ],
  [ROUTES.CONTRACTS]: [STATUSES.BOUND, STATUSES.CANCELLED],
  [ROUTES.REFERRALS]: [STATUSES.REFERRED],
};

export const draftsColumns = [
  {
    className: "w-32",
    displayName: "Reference",
    formatter: (v) => `#${v}`,
    path: "ref",
  },
  {
    className: "w-48",
    displayName: "Insured",
    path:
      process.env.DEFAULT_PRODUCT_REF === "ah"
        ? "submission.base.insured_name"
        : "submission.general_information.insured_name",
  },
  {
    className: "w-32",
    displayName: "Created date",
    formatter: dateFormatter,
    path: "createdAt",
  },
  {
    className: "w-32",
    displayName: "Last updated",
    formatter: dateTimeFormatter,
    path: "updatedAt",
  },
  {
    className: "w-32",
    displayName: "Status",
    path: "status",
    formatter: (value) => <Status status={value} kind="small" />,
  },
];

export const referralsColumns = [
  {
    className: "w-32",
    displayName: "Reference",
    formatter: (v) => `#${v}`,
    path: "ref",
  },
  {
    className: "w-48",
    displayName: "Insured",
    path:
      process.env.DEFAULT_PRODUCT_REF === "ah"
        ? "submission.base.insured_name"
        : "submission.general_information.insured_name",
  },
  {
    className: "w-32",
    displayName: "Created date",
    formatter: dateFormatter,
    path: "createdAt",
  },
  {
    className: "w-32",
    displayName: "Last updated",
    formatter: dateTimeFormatter,
    path: "updatedAt",
  },
  {
    className: "w-28",
    displayName: "Status",
    path: "status",
    formatter: (value) => <Status status={value} kind="small" />,
  },
  {
    className: "w-24",
    displayName: "Endorsement status",
    path: "state",
    formatter: (value) => {
      if (typeof value !== "string") {
        const status = Object.entries(value)[0][1];

        if (status !== STATUSES.IDLE) {
          return <Status status={status} kind="small" />;
        }
      }

      return false;
    },
  },
];

export const policyColumns = [
  {
    className: "w-32",
    displayName: "Reference",
    formatter: (v) => `#${v}`,
    path: "ref",
  },
  {
    className: "w-48",
    displayName: "Insured",
    path:
      process.env.DEFAULT_PRODUCT_REF === "ah"
        ? "submission.base.insured_name"
        : "submission.general_information.insured_name",
  },
  {
    className: "w-32",
    displayName: "Created date",
    formatter: dateFormatter,
    path: "createdAt",
  },
  {
    className: "w-32",
    displayName: "Last updated",
    formatter: dateTimeFormatter,
    path: "updatedAt",
  },
  {
    className: "w-32",
    displayName: "Inception date",
    formatter: dateFormatter,
    path:
      process.env.DEFAULT_PRODUCT_REF === "ah"
        ? "submission.base.inception_date"
        : "boundQuote.base.inception_date",
  },
  {
    className: "w-28",
    displayName: "Policy status",
    path: "status",
    formatter: (value) => <Status status={value} kind="small" />,
  },
  {
    className: "w-24",
    displayName: "Endorsement status",
    path: "state",
    formatter: (value) => {
      if (typeof value !== "string") {
        const status = Object.entries(value)[0][1];

        if (status !== STATUSES.IDLE) {
          return <Status status={status} kind="small" />;
        }
      }

      return false;
    },
  },
];

export const columns = {
  [ROUTES.DRAFTS]: draftsColumns,
  [ROUTES.CONTRACTS]: policyColumns,
  [ROUTES.REFERRALS]: referralsColumns,
};
