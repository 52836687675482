import get from "lodash.get";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { ACTIONS, STATUSES } from "../../../constants";
import { currencyFormatter, dateFormatter } from "../../../formatters";
import Banner from "../../components/Banner";
import { useAuth } from "../../hooks";

const PremiumDebugger = (data) => {
  const debug = get(data, "data.__debugger__", {});
  const tdclx = "border border-gray-900 p-2";

  return (
    <details>
      <summary className="cursor-pointer select-none">Debugger</summary>
      <div>
        <table className="border border-gray-900 w-1/2 mb-8 mt-8">
          <tbody>
            <tr>
              <td className={tdclx}>isTechnical</td>
              <td className={tdclx}>{JSON.stringify(debug.isTechnical)}</td>
            </tr>
            <tr>
              <td className={tdclx}>hasMaterialReasons</td>
              <td className={tdclx}>{JSON.stringify(debug.hasMaterialReasons)}</td>
            </tr>
            <tr>
              <td className={tdclx}>hasEndoReasons</td>
              <td className={tdclx}>{JSON.stringify(debug.hasEndoReasons)}</td>
            </tr>
            <tr>
              <td className={tdclx}>hasOriginalReasons</td>
              <td className={tdclx}>{JSON.stringify(debug.hasOriginalReasons)}</td>
            </tr>
            <tr>
              <td className={tdclx}>originalGrossPremium</td>
              <td className={tdclx}>{debug.originalGrossPremium}</td>
            </tr>
            <tr>
              <td className={tdclx}>minimumDeposit</td>
              <td className={tdclx}>{debug.minimumDeposit}</td>
            </tr>
            <tr>
              <td className={tdclx}>inception</td>
              <td className={tdclx}>{moment(debug.inception).format("DD/MMM/YYYY")}</td>
            </tr>
            <tr>
              <td className={tdclx}>prevEffectiveFrom</td>
              <td className={tdclx}>{moment(debug.prevEffectiveFrom).format("DD/MMM/YYYY")}</td>
            </tr>
            <tr>
              <td className={tdclx}>nextEffectiveFrom</td>
              <td className={tdclx}>{moment(debug.nextEffectiveFrom).format("DD/MMM/YYYY")}</td>
            </tr>
            <tr>
              <td className={tdclx}>tally</td>
              <td className={tdclx}>{debug.tally}</td>
            </tr>
            <tr>
              <td className={tdclx}>totalPremiumDue</td>
              <td className={tdclx}>{debug.totalPremiumDue}</td>
            </tr>
          </tbody>
        </table>

        <table className="border border-gray-900 w-1/2 mb-8 mt-8">
          <thead>
            <tr>
              <th className="text-left px-2">version</th>
              <th className="text-left px-2">premium</th>
              <th className="text-left px-2">daily cost</th>
              <th className="text-left px-2">days on version</th>
              <th className="text-left px-2">actual premium</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={tdclx}>previous</td>
              <td className={tdclx}>{debug.prevPremium}</td>
              <td className={tdclx}>{debug.prevPremiumDailyRate}</td>
              <td className={tdclx}>{JSON.stringify(debug.prevPremiumDays)}</td>
              <td className={tdclx}>{debug.prevPremiumActual}</td>
            </tr>
            <tr>
              <td className={tdclx}>next</td>
              <td className={tdclx}>{debug.nextPremium}</td>
              <td className={tdclx}>{debug.nextPremiumDailyRate}</td>
              <td className={tdclx}>{JSON.stringify(debug.nextPremiumDays)}</td>
              <td className={tdclx}>{debug.nextPremiumActual}</td>
            </tr>
          </tbody>
        </table>

        <table className="border border-gray-900 w-1/2 mb-8 mt-8">
          <tbody>
            <tr>
              <td className={tdclx}>Actual Endorsement premium</td>
              <td className={tdclx}>{debug.proposedEndoPremiumFixed}</td>
            </tr>
            <tr>
              <td className={tdclx}>Actual Endorsement premium MD check</td>
              <td className={tdclx}>{debug.proposedEndoPremiumMDFixed}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </details>
  );
};

const Banners = ({ contractData }) => {
  const { checkPermissions, isInternalEnv } = useAuth(contractData);
  const { status, type } = contractData;
  const isEndorsement = type === "endorsement";
  const isReferred = status === STATUSES.REFERRED;
  const isNtu = status === STATUSES.REJECTED_NOT_TAKEN_UP;
  const isNp = status === STATUSES.REJECTED_NOT_PROGRESSED;
  const isDeclined = status === STATUSES.DECLINED;
  const isCancelled =
    status === STATUSES.CANCELLED || (isEndorsement && get(contractData, "cancellation"));
  const hasEndoPremium = Boolean(get(contractData, "endorsement_premium"));
  const actualPremium = get(contractData, "endorsement_premium.actualPremium");
  const actualPremiumAmount = get(actualPremium, "amount");
  const manualReferralReason = [
    "Broker has chosen to pass this to you manually, check for any unread messages",
  ];
  const referralReasons = get(contractData, "referralReasons", []);
  const finalReasons = referralReasons.length > 0 ? referralReasons : manualReferralReason;
  const isReferredCancellation = isReferred && referralReasons.includes("Known losses.");

  return (
    <>
      {checkPermissions(ACTIONS.VIEW_REFERRAL_REASONS) && isReferred && (
        <Banner
          className="mb-8"
          color="orange"
          headingText={`This ${
            (isReferredCancellation && "cancellation") ||
            (isEndorsement && "endorsement") ||
            "submission"
          } has been referred to you for the following reason(s):`}
        >
          <ul className="list-disc list-inside">
            {finalReasons.map((reason) => (
              <li key={reason}>{reason}</li>
            ))}
          </ul>
        </Banner>
      )}

      {!checkPermissions(ACTIONS.VIEW_REFERRAL_REASONS) && isReferred && (
        <Banner
          className="mb-8"
          color="orange"
          headingText={`Your ${
            (isReferredCancellation && "cancellation") ||
            (isEndorsement && "endorsement") ||
            "submission"
          } has been referred to our underwriters for review.`}
        />
      )}

      {isNtu && (
        <Banner
          className="mb-8"
          color="red"
          headingText="Your submission was set to not taken up for the following reason(s):"
        >
          <ul className="list-disc list-inside">
            <li>{get(contractData, "rejection.reason")}</li>
          </ul>

          {get(contractData, "rejection.note") && (
            <div className="mt-4">
              <p className="font-medium">Note</p>
              <p>{get(contractData, "rejection.note")}</p>
            </div>
          )}
        </Banner>
      )}

      {isNp && (
        <Banner
          className="mb-8"
          color="red"
          headingText="Your submission was set to not progressed for the following reason(s):"
        >
          <ul className="list-disc list-inside">
            <li>{get(contractData, "rejection.reason")}</li>
          </ul>

          {get(contractData, "rejection.note") && (
            <div className="mt-4">
              <p className="font-medium">Note</p>
              <p>{get(contractData, "rejection.note")}</p>
            </div>
          )}
        </Banner>
      )}

      {isDeclined && process.env.DEFAULT_PRODUCT_REF === "ah" && (
        <Banner
          className="mb-8"
          color="red"
          headingText="Your submission was set to decline to quote for the following reason(s):"
        >
          <ul className="list-disc list-inside">
            {contractData?.declineReasons?.map((reason) => (
              <li key={reason}>{reason}</li>
            ))}
          </ul>
        </Banner>
      )}

      {isDeclined && process.env.DEFAULT_PRODUCT_REF !== "ah" && (
        <Banner
          className="mb-8"
          color="red"
          headingText="Your submission was set to decline to quote for the following reason(s):"
        >
          <ul className="list-disc list-inside">
            <li>{get(contractData, "declension.reason")}</li>
          </ul>

          {get(contractData, "declension.note") && (
            <div className="mt-4">
              <p className="font-medium">Note</p>
              <p>{get(contractData, "declension.note")}</p>
            </div>
          )}
        </Banner>
      )}

      {isReferredCancellation && (
        <Banner className="mb-8" color="red" headingText="Cancellation details.">
          {contractData?.submission?.quote && (
            <p className="mb-2">
              <span className="font-medium">Reason for cancellation: </span>
              {contractData?.submission?.quote?.reason_other ||
                contractData?.submission?.quote?.reason}
            </p>
          )}

          {contractData?.submission?.quote?.cancellation_date && (
            <p className="mb-2">
              <span className="font-medium">Cancellation date: </span>
              {dateFormatter(contractData?.submission?.quote?.cancellation_date)}
            </p>
          )}
        </Banner>
      )}

      {isCancelled && (
        <Banner className="mb-8" color="red" headingText="This policy has been cancelled.">
          {get(contractData, "cancellation.reason") && (
            <p className="mb-2">
              <span className="font-medium">Reason for cancellation: </span>
              {get(contractData, "cancellation.reason_other") ||
                get(contractData, "cancellation.reason")}
            </p>
          )}

          {get(contractData, "cancellation.cancellation_date") && (
            <p className="mb-2">
              <span className="font-medium">Cancellation date: </span>
              {dateFormatter(get(contractData, "cancellation.cancellation_date"))}
            </p>
          )}
        </Banner>
      )}

      {hasEndoPremium && isEndorsement && (
        <Banner className="mb-8" color="red" headingText="Endorsement premium change:">
          {actualPremium && (
            <p className="mb-2">
              {actualPremiumAmount < 0 && <span className="font-medium">Return premium: </span>}
              {actualPremiumAmount > 0 && <span className="font-medium">Additional premium: </span>}
              {currencyFormatter({ amount: Math.abs(actualPremium?.amount) })}
            </p>
          )}

          {isInternalEnv && <PremiumDebugger data={get(contractData, "endorsement_premium")} />}
        </Banner>
      )}
    </>
  );
};

Banners.propTypes = {
  contractData: PropTypes.object.isRequired,
};

export default Banners;
