import formatDateFns from "date-fns/format";
import parseISO from "date-fns/parseISO";
import isFinite from "lodash.isfinite";

export const currencyFormatter = (args = {}) => {
  const { amount, currency = "USD", ...options } = args;

  if (!amount && amount !== 0) {
    return false;
  }

  return Number(amount).toLocaleString("en-US", {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options,
  });
};

export const numberFormatter = (value) => {
  const parsedAmount = Number.parseFloat(value);

  if (!isFinite(parsedAmount)) {
    return value;
  }

  return Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
    style: "currency",
    currency: "USD",
  }).format(value);
};

export const addressFormatter = (separator) => (values) => values.filter(Boolean).join(separator);

export const formatNumber = (num, dec = 0) => (num ? num.toFixed(dec) : "");

export const dateFormatter = (date) => {
  if (!date) {
    return false;
  }

  return formatDateFns(typeof date === "string" ? parseISO(date) : date, "dd/MMM/yyyy");
};

export const dateTimeFormatter = (date) => {
  if (!date) {
    return false;
  }

  return formatDateFns(typeof date === "string" ? parseISO(date) : date, "dd/MMM/yyyy HH:mm");
};

export const fullNameFormatter = (values) => {
  const [firstName, lastName] = Array.isArray(values)
    ? values
    : [values.firstName, values.lastName];

  return `${firstName} ${lastName}`;
};

export const fullAddress = (values) => {
  // eslint-disable-next-line camelcase
  const { address_line_1, address_line_2, state, zipcode } = values || {};

  // eslint-disable-next-line camelcase
  return `${address_line_1 || ""} ${address_line_2 || ""} ${state || ""} ${zipcode || ""}`;
};
