/* eslint-disable no-restricted-globals */
import Ajv from "ajv";
import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import moment from "moment";
import { compile } from "path-to-regexp";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { ACTIONS, MODALS, ROUTES } from "../../../constants";
import ajv from "../../../helpers/ajv";
import { resolveSchema } from "../../../helpers/resolveSchema";
import Debugger from "../../components/Debugger";
import Form from "../../components/Form";
import { cleanObject } from "../../helpers/cleanObject";
import mapChildren from "../../helpers/mapChildren";
import { useModal } from "../../hooks";
import { useHistoryBlock } from "../../hooks/useHistoryBlock";
import { useMagicLink } from "../../hooks/useMagicLink";
import { useMutateContractQuery } from "../../hooks/useMutateContractQuery";
import SubmitBespokeEndorsementModal from "../../modals/SubmitBespokeEndorsementModal";
import FormNavigation from "./FormNavigation";

const SubmissionForm = ({
  currentContractData,
  isDebuggerVisible,
  isEditEndorsementRoute,
  isEditing,
  isNewBespokeEndorsementRoute,
  isNewEndorsementRoute,
  isUploading,
  setDebuggerVisible,
  showDraftButton,
  schemaData,
  isRenewalRoute,
}) => {
  const [showBespokeModal, setShowBespokeModal] = useState(isNewBespokeEndorsementRoute);
  const params = useParams();
  const { productRef, contractId, endorsementId } = params;
  const [bespokeEndoFormValues, setBespokeEndoFormValues] = useState({});
  const { push } = useHistory();
  const { createContract, updateContract, isCreating, isUpdating } = useMutateContractQuery();
  const queryClient = useQueryClient();

  const prevSubmission = currentContractData.submission;

  const { initialFormValues } = useMagicLink(prevSubmission);

  const [formErrors, setFormErrors] = useState();
  const [formValues, setFormValues] = useState(initialFormValues || {});
  const isFormDirty = JSON.stringify(initialFormValues) !== JSON.stringify(formValues);
  const clonedSchema = cloneDeep(schemaData);
  const validate = ajv.compile(schemaData);
  const isValid = validate(formValues);
  const isSubmitting = isCreating || isUpdating || isUploading;
  const { showModal } = useModal();

  const forcePush = (path) => push(path, { isForced: true });

  useEffect(() => {
    const clonedPrevSubmission = cloneDeep(prevSubmission);
    const nextSubmission = cloneDeep(prevSubmission);

    if (isRenewalRoute) {
      set(nextSubmission, "base.inception_date", clonedPrevSubmission.base.expiry_date);
      set(
        nextSubmission,
        "base.insured_age",
        clonedPrevSubmission.base.insured_age + clonedPrevSubmission.base.policy_period
      );
      set(
        nextSubmission,
        "base.expiry_date",
        moment
          .utc(clonedPrevSubmission.base.expiry_date)
          .add(clonedPrevSubmission.base.policy_period, "years")
          .format()
      );

      setFormValues(nextSubmission);
    }
  }, []);

  const handleChange = (...args) => {
    const nextFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;

      set(nextFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;

      if (name === "base.inception_date") {
        const newDate = moment.utc(value).add(1, "year").format();

        set(nextFormValues, "base.expiry_date", newDate);
      }

      if (name === "base.policy_period") {
        const inceptionDate = formValues?.base?.inception_date;

        if (inceptionDate) {
          const newDate = moment.utc(inceptionDate).add(value, "years").format();

          set(nextFormValues, "base.expiry_date", newDate);
        }
      }

      set(nextFormValues, name, value);
    }

    const ajv2 = new Ajv({
      allErrors: true,
      coerceTypes: true,
      useDefaults: true,
      removeAdditional: "all",
    });

    const clonedschema2 = cloneDeep(schemaData);

    resolveSchema(clonedschema2, nextFormValues);
    ajv2.validate(clonedschema2, nextFormValues);
    cleanObject(nextFormValues);
    setFormValues(nextFormValues);
  };

  const handleSubmit = async (submission) => {
    const renewedFrom = isRenewalRoute ? contractId : undefined;
    const submit = isEditing ? updateContract : createContract;
    const type = isEditing ? ACTIONS.UPDATE_SUBMISSION : ACTIONS.CREATE_SUBMISSION;
    const data = { type, renewedFrom, payload: { submission } };
    const res = await submit(
      { productRef, contractId, endorsementId, data },
      {
        onSuccess: () => {
          queryClient.resetQueries("contract", { productRef, contractId });
          queryClient.resetQueries("endorsements", { productRef, contractId });
        },
      }
    );
    const nextId = res?.data?.data?.id;

    if (isNewEndorsementRoute || isEditEndorsementRoute) {
      return forcePush(compile(ROUTES.CONTRACT_VIEW_ENDORSEMENTS)(params));
    }

    return forcePush(compile(ROUTES.CONTRACT_VIEW_DETAILS)({ ...params, contractId: nextId }));
  };

  const handleBeforeSubmit = () => {
    const parsed = Object.keys(schemaData.properties).reduce(
      (prev, next) => ({ ...prev, [next]: prev[next] || {} }),
      formValues || {}
    );

    const validateForm = ajv.compile(schemaData);
    const isFormValid = validateForm(parsed);
    const errors = validateForm?.errors
      ?.map((error) => {
        const { missingProperty } = error?.params;
        const dataPath = error.dataPath.substring(1);
        return {
          ...error,
          path: missingProperty ? `${dataPath}.${missingProperty}` : dataPath,
        };
      })
      .filter((error) => error.keyword !== "enum");

    if (isNewEndorsementRoute || isEditEndorsementRoute) {
      return showModal(MODALS.SUBMIT_ENDORSEMENT, {
        formData: formValues,
        handleSubmitForm: handleSubmit,
      });
    }

    if (!isFormValid) {
      return setFormErrors(errors);
    }

    return handleSubmit(formValues);
  };

  const handleSubmitDraft = async () => {
    const submission = formValues;
    const submit = isEditing ? updateContract : createContract;
    const type = isEditing ? ACTIONS.UPDATE_DRAFT_SUBMISSION : ACTIONS.CREATE_DRAFT_SUBMISSION;
    const data = { type, payload: { submission } };
    const res = await submit({ productRef, contractId, endorsementId, data });
    const nextId = res?.data?.data?.id;

    if (isNewEndorsementRoute || isEditEndorsementRoute) {
      return forcePush(compile(ROUTES.ENDORSEMENT_EDIT)({ ...params, endorsementId: nextId }));
    }

    return forcePush(compile(ROUTES.CONTRACT_EDIT)({ ...params, contractId: nextId }));
  };

  const handleBeforeSubmitDraft = () => {
    const parsed = Object.keys(schemaData.properties).reduce(
      (prev, next) => ({ ...prev, [next]: prev[next] || {} }),
      formValues || {}
    );

    const validateForm = ajv.compile(schemaData);
    validateForm(parsed);

    const errors = validateForm?.errors?.map((error) => {
      const { missingProperty } = error?.params;
      const dataPath = error.dataPath.substring(1);
      return {
        ...error,
        path: missingProperty ? `${dataPath}.${missingProperty}` : dataPath,
      };
    });

    const insuredErrors = errors?.filter(
      (error) => error?.params?.missingProperty === "insured_name"
    );

    if (insuredErrors?.length > 0) {
      return setFormErrors(insuredErrors);
    }

    return handleSubmitDraft();
  };

  resolveSchema(clonedSchema, formValues);
  useHistoryBlock({ isDirty: isFormDirty });

  useEffect(() => {
    const errors = document.querySelectorAll("[data-error=true]");

    if (errors.length > 0) {
      window.scrollTo({ top: errors?.[0]?.offsetTop - 80, behavior: "smooth" });
    }
  }, [formErrors]);

  return (
    <div>
      {showBespokeModal && (
        <SubmitBespokeEndorsementModal
          bespokeEndoFormValues={bespokeEndoFormValues}
          formData={formValues}
          handleClose={() => setShowBespokeModal(false)}
          setBespokeEndoFormValues={setBespokeEndoFormValues}
          handleSubmitForm={handleSubmit}
        />
      )}

      {isDebuggerVisible && (
        <Debugger
          formValues={formValues}
          handleClose={() => setDebuggerVisible(false)}
          isFormValid={isValid}
          referralsData={[]}
          validationErrors={validate.errors || []}
        />
      )}

      <div className="mx-10 pb-24 flex items-start mt-12">
        <Form className="max-w-3xl w-full" id="submissionForm">
          {mapChildren({
            formValues,
            onChange: handleChange,
            parentKey: "",
            parentSchema: clonedSchema,
            setFormValues,
            validationErrors: formErrors,
          })}
        </Form>

        <FormNavigation
          canSaveDraft={!isSubmitting}
          canSubmit={!isSubmitting}
          formValues={formValues}
          handleSubmit={handleBeforeSubmit}
          handleSubmitDraft={handleBeforeSubmitDraft}
          schema={clonedSchema}
          showDraftButton={showDraftButton}
        />
      </div>
    </div>
  );
};

export default SubmissionForm;
