import { compile } from "path-to-regexp";
import React, { useState } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { ROUTES } from "../../../constants";
import PageLoader from "../../components/PageLoader";
import Status from "../../components/Status";
import Tabs from "../../components/Tabs";
import { useContractQuery, usePrivateRoute } from "../../hooks";
import { useAttachmentQuery } from "../../hooks/useAttachmentQuery";
import { ModalSwitch } from "../../hooks/useModal";
import { useProductCheck } from "../../hooks/useProductCheck";
import { useScrollTop } from "../../hooks/useScrollTop";
import ContractViewAudit from "../ContractViewAudit";
import ContractViewDetails from "../ContractViewDetails";
import ContractViewEndorsements from "../ContractViewEndorsements";
import Header from "../Header";
import MessageBox from "../MessageBox";

const ContractView = () => {
  const params = useParams();
  const [isMessageBoxVisible, setMessageBoxVisible] = useState(false);
  const toggleMessageBox = () => setMessageBoxVisible(!isMessageBoxVisible);

  const {
    auditData,
    clauseData,
    contractData,
    endorsementsData,
    isLoading,
    schemaData,
    snapshotData,
    canCreateEndorsements,
    hasActiveEndorsements,
    hasEndorsements,
    isCreating,
    isDraft,
  } = useContractQuery();

  const { attachmentsData, isLoading: areAttachmentsLoading } = useAttachmentQuery();

  useScrollTop();

  if (isLoading || areAttachmentsLoading || isCreating) {
    return <PageLoader />;
  }

  return (
    <>
      <ModalSwitch />

      <div className="shadow">
        <Header
          contractData={contractData}
          canCreateEndorsements={canCreateEndorsements}
          isDraft={isDraft}
          toggleMessageBox={toggleMessageBox}
          attachmentsData={attachmentsData}
        />

        <Tabs className="bg-white border-t border-white px-8">
          <Tabs.Tab to={compile(ROUTES.CONTRACT_VIEW_DETAILS)(params)}>Details</Tabs.Tab>

          {hasEndorsements && (
            <Tabs.Tab to={compile(ROUTES.CONTRACT_VIEW_ENDORSEMENTS)(params)}>
              Endorsements{" "}
              {hasActiveEndorsements && (
                <Status kind="small" statusText="New" className="text-red-1000 bg-red-200" />
              )}
            </Tabs.Tab>
          )}

          <Tabs.Tab to={compile(ROUTES.CONTRACT_VIEW_AUDIT_LOG)(params)}>Audit log</Tabs.Tab>
        </Tabs>
      </div>

      <div className="p-8">
        <Switch>
          <Route
            exact
            path={ROUTES.CONTRACT_VIEW_DETAILS}
            render={() => (
              <ContractViewDetails
                clauseData={clauseData}
                contractData={contractData}
                schemaData={schemaData}
                snapshotData={snapshotData}
              />
            )}
          />

          <Route
            exact
            path={ROUTES.CONTRACT_VIEW_AUDIT_LOG}
            render={() => (
              <ContractViewAudit
                auditData={auditData}
                clauseData={clauseData}
                schemaData={schemaData}
              />
            )}
          />

          {hasEndorsements && (
            <Route
              exact
              path={ROUTES.CONTRACT_VIEW_ENDORSEMENTS}
              render={() => (
                <ContractViewEndorsements
                  parentData={contractData}
                  endorsementsData={endorsementsData}
                  schemaData={schemaData}
                />
              )}
            />
          )}

          <Redirect to={ROUTES.CONTRACT_VIEW_DETAILS} />
        </Switch>
      </div>

      {isMessageBoxVisible && (
        <MessageBox handleClose={toggleMessageBox} contractData={contractData} />
      )}
    </>
  );
};

const ContractViewPage = () => {
  const { isLoading } = usePrivateRoute();

  useProductCheck();

  if (isLoading) {
    return <PageLoader />;
  }

  return <ContractView />;
};

export default ContractViewPage;
