import React from "react";
import { STATUSES } from "../../../constants";
import { dateFormatter, dateTimeFormatter } from "../../../formatters";
import Status from "../../components/Status";

export const columns = [
  {
    className: "w-32",
    displayName: "Reference",
    formatter: (v) => `#${v}`,
    path: "ref",
  },
  {
    className: "w-48",
    displayName: "Insured name",
    path:
      process.env.DEFAULT_PRODUCT_REF === "ah"
        ? "submission.base.insured_name"
        : "submission.general_information.insured_name",
    formatter: (value) => <span className="font-semibold">{value}</span>,
  },
  {
    className: "w-32",
    displayName: "Created",
    formatter: dateFormatter,
    path: "createdAt",
  },
  {
    className: "w-32",
    displayName: "Last updated",
    formatter: dateTimeFormatter,
    path: "updatedAt",
  },
  {
    className: "w-28",
    displayName: "Policy status",
    path: "status",
    formatter: (value) => <Status status={value} kind="small" />,
  },
  {
    className: "w-24",
    displayName: "Endorsement status",
    path: "state",
    formatter: (value) => {
      if (typeof value !== "string") {
        const status = Object.entries(value)[0][1];

        if (status !== STATUSES.IDLE) {
          return <Status status={status} kind="small" />;
        }
      }

      return false;
    },
  },
  {
    className: "w-24",
    displayName: "Unread messages",
    path: "messagesCount",
    formatter: (messagesCount) => (
      <Status
        className={messagesCount === 0 ? "text-gray-900 bg-gray-200" : "text-red-1000 bg-red-100"}
        kind="small"
        statusText={messagesCount.toString()}
      />
    ),
  },
];
