/* eslint-disable no-restricted-globals */
import Ajv from "ajv";
import traverse from "json-schema-traverse";
import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import isEmpty from "lodash.isempty";
import set from "lodash.set";
import moment from "moment";
import { compile } from "path-to-regexp";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { ACTIONS, MODALS, ROUTES } from "../../../constants";
import { CONTRACT_TYPES } from "../../../constants/contractTypes";
import ajv from "../../../helpers/ajv";
import { resolveSchema } from "../../../helpers/resolveSchema";
import Debugger from "../../components/Debugger";
import Form from "../../components/Form";
import { cleanObject } from "../../helpers/cleanObject";
import mapChildren from "../../helpers/mapChildren";
import { toBase64 } from "../../helpers/toBase64";
import { useAuth, useModal } from "../../hooks";
import { useHistoryBlock } from "../../hooks/useHistoryBlock";
import { useMagicLink } from "../../hooks/useMagicLink";
import { useMutateContractQuery } from "../../hooks/useMutateContractQuery";
import { useReferralsCheck } from "../../hooks/useReferralsCheck";
import SubmitBespokeEndorsementModal from "../../modals/SubmitBespokeEndorsementModal";
import AHSubmissionForm from "./AHSubmissionForm";
import FormNavigation from "./FormNavigation";
import ReferralsBanner from "./ReferralsBanner";

const byFileType = (node) => node.type === "file";

const getSchemasWithSums = (schemaData) => {
  const result = [];

  traverse(schemaData, {
    allKeys: true,
    cb: (schema, pointer) => {
      if ("ui:sum:amount" in schema) {
        result.push([schema, pointer]);
      }
    },
  });

  return result;
};

const clearValues = (formValues) => {
  const clonedValues = cloneDeep(formValues);
  const dolKey = "loss_record.loss_details";
  const isDolArray = Array.isArray(get(clonedValues, dolKey));

  if (isDolArray) {
    const filteredDol = get(clonedValues, dolKey, []).filter((item) => !isEmpty(item));

    /* Remove empty array items from DOL */
    set(clonedValues, dolKey, filteredDol);
  }

  return clonedValues;
};

const SubmissionForm = ({
  contractData,
  currentContractData,
  isDebuggerVisible,
  isEditEndorsementRoute,
  isEditing,
  isNewBespokeEndorsementRoute,
  isNewEndorsementRoute,
  isRenewalRoute,
  isUploading,
  schemaData,
  setDebuggerVisible,
  showDraftButton,
  uploadAttachment,
}) => {
  const [showBespokeModal, setShowBespokeModal] = useState(isNewBespokeEndorsementRoute);
  const params = useParams();
  const { productRef, contractId, endorsementId } = params;
  const [bespokeEndoFormValues, setBespokeEndoFormValues] = useState({});

  const { push } = useHistory();
  const { createContract, updateContract, isCreating, isUpdating } = useMutateContractQuery();
  const { showModal } = useModal();
  const queryClient = useQueryClient();
  const { isInternalEnv } = useAuth();
  const schemasWithSums = getSchemasWithSums(schemaData);
  const prevSubmission = currentContractData.submission;
  const { initialFormValues } = useMagicLink(prevSubmission);
  const [formErrors, setFormErrors] = useState();
  const [formValues, setFormValues] = useState(initialFormValues || {});
  const isFormDirty = JSON.stringify(initialFormValues) !== JSON.stringify(formValues);
  const clonedSchema = cloneDeep(schemaData);
  const validate = ajv.compile(schemaData);
  const isValid = validate(formValues);
  // const isInsuredValid = formValues?.general_information?.insured_name?.trim().length > 0;
  const isRenewal = currentContractData.type === CONTRACT_TYPES.RENEWAL;
  const isSubmitting = isCreating || isUpdating || isUploading;
  const { checkReferrals, referralsData } = useReferralsCheck();

  const forcePush = (path) => push(path, { isForced: true });

  const handleChange = (...args) => {
    const nextFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;

      set(nextFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;

      if (name === "quote.inception_date") {
        const newDate = moment.utc(value).add(1, "year").format();

        set(nextFormValues, "quote.expiry_date", newDate);
      }

      schemasWithSums.forEach(([schema, pointer]) => {
        const arrOfNames = schema["ui:sum:amount"];

        if (arrOfNames.includes(name)) {
          const data = Object.fromEntries(new FormData(document.forms[0]).entries());

          const total = arrOfNames.reduce((prev, next) => {
            const val = get(data, next, "").split(",").join("") || 0;
            const parsedValue = parseFloat(val);

            return prev + parsedValue;
          }, 0);

          set(nextFormValues, pointer.split("/properties/").join(".").substring(1), {
            amount: total,
            currency: "USD",
          });
        }
      });

      set(nextFormValues, name, value);
    }

    const ajv2 = new Ajv({
      allErrors: true,
      coerceTypes: true,
      useDefaults: true,
      removeAdditional: "all",
    });

    const clonedschema2 = cloneDeep(schemaData);

    resolveSchema(clonedschema2, nextFormValues);
    ajv2.validate(clonedschema2, nextFormValues);
    cleanObject(nextFormValues);
    checkReferrals({ prevSubmission, nextSubmission: nextFormValues });
    setFormValues(nextFormValues);
  };

  const maybeUploadFile = async (inputFile, id) => {
    const file = inputFile?.files?.[0];

    if (file) {
      const content = await toBase64(file);

      await uploadAttachment({
        contractId: id,
        productRef,
        data: {
          content,
          fileName: file.name,
          documentType: "submission_attachment",
          subType: "loss_run",
          documentId: inputFile.dataset.uuid,
        },
      });
    }
  };

  const handleSubmit = async (formData, bespokeSubmission) => {
    const inputFile = Array.from(document.forms.submissionForm.elements).find(byFileType);
    const submission = clearValues(formData);
    const submit = isEditing ? updateContract : createContract;
    const type = isEditing ? ACTIONS.UPDATE_SUBMISSION : ACTIONS.CREATE_SUBMISSION;
    const renewedFrom = isRenewalRoute ? contractId : undefined;
    const data = { type, renewedFrom, payload: { submission, ...bespokeSubmission } };
    const res = await submit(
      { productRef, contractId, endorsementId, data },
      {
        onSuccess: () => {
          queryClient.resetQueries("contract", { productRef, contractId });
          queryClient.resetQueries("endorsements", { productRef, contractId });
        },
      }
    );
    const nextId = res?.data?.data?.id;

    await maybeUploadFile(inputFile, nextId);

    if (isNewEndorsementRoute || isEditEndorsementRoute) {
      return forcePush(compile(ROUTES.CONTRACT_VIEW_ENDORSEMENTS)(params));
    }

    return forcePush(compile(ROUTES.CONTRACT_VIEW_DETAILS)({ ...params, contractId: nextId }));
  };

  const handleSubmitDraft = async () => {
    const inputFile = Array.from(document.forms.submissionForm.elements).find(byFileType);
    const submission = clearValues(formValues);
    const submit = isEditing ? updateContract : createContract;
    const type = isEditing ? ACTIONS.UPDATE_DRAFT_SUBMISSION : ACTIONS.CREATE_DRAFT_SUBMISSION;
    const data = { type, payload: { submission } };
    const res = await submit({ productRef, contractId, endorsementId, data });
    const nextId = res?.data?.data?.id;

    await maybeUploadFile(inputFile, nextId);

    if (isNewEndorsementRoute || isEditEndorsementRoute) {
      return forcePush(compile(ROUTES.ENDORSEMENT_EDIT)({ ...params, endorsementId: nextId }));
    }

    return forcePush(compile(ROUTES.CONTRACT_EDIT)({ ...params, contractId: nextId }));
  };

  const handleBeforeSubmit = () => {
    const parsed = Object.keys(schemaData.properties).reduce(
      (prev, next) => ({ ...prev, [next]: prev[next] || {} }),
      formValues || {}
    );

    set(parsed, "diving.type_of_diving", parsed?.diving?.type_of_diving || {});

    if (parsed?.loss_record?.has_insured_had_jones_act_claims === "Yes (provide details)") {
      set(parsed, "loss_record.loss_details", parsed?.loss_record?.loss_details || [{}]);
    }

    const validateForm = ajv.compile(schemaData);
    const isFormValid = validateForm(parsed);
    const errors = validateForm?.errors
      ?.map((error) => {
        const { missingProperty } = error?.params;
        const dataPath = error.dataPath.substring(1);
        return {
          ...error,
          path: missingProperty ? `${dataPath}.${missingProperty}` : dataPath,
        };
      })
      .filter((error) => error.keyword !== "enum");

    if (!isFormValid) {
      return setFormErrors(errors);
    }

    if (isNewBespokeEndorsementRoute) {
      return showModal(MODALS.SUBMIT_BESPOKE_ENDORSEMENT, {
        bespokeEndoFormValues,
        formData: formValues,
        handleSubmitForm: handleSubmit,
        setBespokeEndoFormValues,
        isConfirmation: true,
      });
    }

    if (isNewEndorsementRoute || isEditEndorsementRoute) {
      return showModal(MODALS.SUBMIT_ENDORSEMENT, {
        formData: formValues,
        handleSubmitForm: handleSubmit,
      });
    }

    if (isRenewalRoute || isRenewal) {
      const bespokeEndos = get(contractData, "bespoke_endorsements", []);
      const hasBespokeEndos = bespokeEndos.length > 0;

      if (hasBespokeEndos) {
        return showModal(MODALS.SUBMIT_RENEWAL_BESPOKE, {
          formData: formValues,
          handleConfirm: handleSubmit,
        });
      }
    }

    return handleSubmit(formValues);
  };

  const handleBeforeSubmitDraft = () => {
    const parsed = Object.keys(schemaData.properties).reduce(
      (prev, next) => ({ ...prev, [next]: prev[next] || {} }),
      formValues || {}
    );

    const validateForm = ajv.compile(schemaData);
    validateForm(parsed);

    const errors = validateForm?.errors?.map((error) => {
      const { missingProperty } = error?.params;
      const dataPath = error.dataPath.substring(1);
      return {
        ...error,
        path: missingProperty ? `${dataPath}.${missingProperty}` : dataPath,
      };
    });

    const insuredErrors = errors?.filter(
      (error) => error?.params?.missingProperty === "insured_name"
    );

    if (insuredErrors?.length > 0) {
      return setFormErrors(insuredErrors);
    }

    return handleSubmitDraft();
  };

  resolveSchema(clonedSchema, formValues);
  useHistoryBlock({ isDirty: isFormDirty });

  useEffect(() => {
    const errors = document.querySelectorAll("[data-error=true]");

    if (errors.length > 0) {
      window.scrollTo({ top: errors?.[0]?.offsetTop - 80, behavior: "smooth" });
    }
  }, [formErrors]);

  return (
    <div>
      {showBespokeModal && (
        <SubmitBespokeEndorsementModal
          bespokeEndoFormValues={bespokeEndoFormValues}
          formData={formValues}
          handleClose={() => setShowBespokeModal(false)}
          setBespokeEndoFormValues={setBespokeEndoFormValues}
          handleSubmitForm={handleSubmit}
        />
      )}

      {isInternalEnv && referralsData.length > 0 && (
        <ReferralsBanner referralsData={referralsData} />
      )}

      {isInternalEnv && isDebuggerVisible && (
        <Debugger
          formValues={formValues}
          handleClose={() => setDebuggerVisible(false)}
          isFormValid={isValid}
          referralsData={referralsData}
          validationErrors={validate.errors || []}
        />
      )}

      <div className="mx-10 pb-24 flex items-start mt-12">
        <Form className="max-w-3xl w-full" id="submissionForm">
          {mapChildren({
            formValues,
            onChange: handleChange,
            parentKey: "",
            parentSchema: clonedSchema,
            setFormValues,
            validationErrors: formErrors,
          })}
        </Form>

        <FormNavigation
          canSaveDraft={!isSubmitting}
          canSubmit={!isSubmitting}
          formValues={formValues}
          handleSubmit={handleBeforeSubmit}
          handleSubmitDraft={handleBeforeSubmitDraft}
          schema={clonedSchema}
          showDraftButton={showDraftButton}
        />
      </div>
    </div>
  );
};

export default process.env.DEFAULT_PRODUCT_REF === "ah" ? AHSubmissionForm : SubmissionForm;
