import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { STATUSES } from "../../../constants";
import * as api from "../../api";
import Accordion from "../../components/Accordion";
import Icon from "../../components/Icon";
import Loader from "../../components/Loader";
import Tag from "../../components/Tag";
import { downloadDocument } from "../../helpers/downloadDocument";
import { useDocumentsQuery } from "../../hooks/useDocumentsQuery";
import { UMR } from "../../../constants/umr";

const contractStatusesMap = {
  [STATUSES.QUOTED]: "quote_document",
  [STATUSES.REFERRED_QUOTED]: "quote_document",
  [STATUSES.IN_PROGRESS]: "quote_document",
  [STATUSES.BOUND]: "quote_document,policy_document,broker_jacket_document,broker_invoice_document",
  [STATUSES.CANCELLED]:
    "quote_document,policy_document,broker_jacket_document,broker_invoice_document",
};

const endoStatusesMap = {
  [STATUSES.QUOTED]: "quote_endorsement_document",
  [STATUSES.REFERRED_QUOTED]: "quote_endorsement_document",
  [STATUSES.IN_PROGRESS]: "quote_endorsement_document",
  [STATUSES.APPLIED]: "bound_endorsement_document,quote_endorsement_document",
  [STATUSES.CANCELLED]: "bound_endorsement_document",
};

const AccordionPanelDocuments = ({ contractData, endorsementId, ...props }) => {
  const { mutateAsync: getAttachmentLink } = useMutation(api.getAttachmentLink);
  const { contractId, productRef } = useParams();
  const { status } = contractData;
  const isReferred = status === STATUSES.REFERRED;
  const isGallagher = contractData?.placingBroker?.umr === UMR.GALLAGHER;
  const isRopner = contractData?.placingBroker?.umr === UMR.ROPNER;
  const referralReasons = get(contractData, "referralReasons", []);
  const isReferredCancellation = isReferred && referralReasons.includes("Known losses.");
  const isCancellation = Boolean(contractData.cancellation) || isReferredCancellation;
  const statusesMap = endorsementId ? endoStatusesMap : contractStatusesMap;
  const expected = statusesMap[isCancellation ? STATUSES.CANCELLED : status] || "";

  const finalExpected = expected
    .split(",")
    .filter((type) => {
      if (isRopner) {
        return type !== "broker_invoice_document";
      }
      if (isGallagher) {
        return type !== "broker_jacket_document";
      }

      return type !== "broker_invoice_document" && type !== "broker_jacket_document";
    })
    .filter(Boolean);

  const { documentsData, isLoading, isError } = useDocumentsQuery({
    contractId,
    endorsementId,
    predicate: (data) => {
      if (finalExpected.length === 0) {
        return true;
      }

      return (
        data.every((item) => item.taskFinished) &&
        finalExpected.every((type) => data.find((item) => item.documentType === type))
      );
    },
  });

  const filteredDocs = documentsData.filter(({ documentType }) =>
    finalExpected?.includes(documentType)
  );

  const hasData = filteredDocs.length > 0;

  const handleDownloadClick = async ({ documentId }) => {
    const res = await getAttachmentLink({ contractId, productRef, documentId });
    const { url, fileName } = get(res, "data.data");

    downloadDocument(url, fileName);
  };

  return (
    <Accordion.Panel
      tag={<Tag text={filteredDocs.length} className="border-gray-400" />}
      {...props}
    >
      {(isLoading || isError) && (
        <div className="p-8">
          <Loader className="mx-auto" />
        </div>
      )}

      {!isLoading && !isError && (
        <>
          {filteredDocs.map((document) => (
            <div
              key={document.fileName}
              className="flex px-3 py-4 border-b border-grey-200 hover:bg-blue-50 hover:text-blue-600 transition"
              onClick={() => handleDownloadClick({ documentId: document.documentId })}
              onKeyPress={() => handleDownloadClick({ documentId: document.documentId })}
              role="button"
              tabIndex="0"
            >
              <p className="pr-4">{document.fileName}</p>
              <Icon name="details" className="cursor-pointer w-6 fill-current text-gray-700" />
            </div>
          ))}

          {!hasData && <p className="text-center my-6">No document available.</p>}
        </>
      )}
    </Accordion.Panel>
  );
};

AccordionPanelDocuments.propTypes = {
  contractData: PropTypes.object.isRequired,
};

export default AccordionPanelDocuments;
