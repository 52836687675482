/* eslint-disable no-restricted-globals */
import { useLocation } from "react-router-dom";
import { parseParams } from "../../helpers/parseParams";

export const useMagicLink = (existingValues) => {
  const { search } = useLocation();
  const initialValues = parseParams(search).initial_form_data;
  const reviver = (k, v) => (typeof v === "object" || isNaN(v) ? v : parseInt(v, 10));
  const parsedValues = JSON.parse(JSON.stringify(initialValues || {}), reviver);
  const initialFormValues = initialValues ? parsedValues : existingValues;

  return { initialFormValues };
};
