import React from "react";
import InputText from "../InputText";

const getInputType = (schema) => {
  if ((schema.type === "string" || schema.type === "number") && "enum" in schema) {
    return undefined;
  }

  if (schema.type === "string") {
    return "text";
  }

  return schema.type;
};

const InputTextSchema = ({ item: schema, ...props }) => (
  <InputText type={getInputType(schema)} min={schema.minimum} max={schema.maximum} {...props} />
);

export default InputTextSchema;
