import React from "react";
import { ACTIONS } from "../../../constants";
import Accordion from "../../components/Accordion";
import Icon from "../../components/Icon";

const AccordionPanelBespoke = ({ bespokeEndos, isEndorsement, handleActions, ...props }) => (
  <Accordion.Panel key="bespoke_endorsements" headingText="Bespoke endorsements" {...props}>
    {bespokeEndos.map((item) => (
      <div key={item.id} className="p-4 border-b border-gray-300 flex justify-between">
        <div>
          <p className="pb-4 text-sm text-gray-800">{item.ref}</p>
          <p>{item.content}</p>
        </div>
        {isEndorsement && bespokeEndos.slice(-1)[0].id === item.id && (
          <div>
            <Icon
              name="edit"
              className="cursor-pointer w-6 fill-current text-gray-700"
              onClick={() => handleActions({ type: ACTIONS.EDIT_BESPOKE, bespoke: item })}
            />
          </div>
        )}
      </div>
    ))}
  </Accordion.Panel>
);

export default AccordionPanelBespoke;
