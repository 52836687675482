import get from "lodash.get";
import { compile } from "path-to-regexp";
import PropTypes from "prop-types";
import React, { memo, useState } from "react";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { ACTIONS, MODALS, ROUTES } from "../../../constants";
import * as api from "../../api";
import Accordion from "../../components/Accordion";
import Button from "../../components/Button";
import BG from "../../components/ButtonGroup";
import H3 from "../../components/H3";
import Table from "../../components/Table";
import Toggle from "../../components/Toggle";
import { downloadDocument } from "../../helpers/downloadDocument";
import { useAuth } from "../../hooks";
import useModal from "../../hooks/useModal";
import AccordionPanelBespoke from "./AccordionPanelBespoke";
import AccordionPanelClauses from "./AccordionPanelClauses";
import AccordionPanelDocuments from "./AccordionPanelDocuments";
import Banners from "./Banners";
import Datapoint from "./Datapoint";
import EndoCancelReferralDecision from "./EndoCancelReferralDecision";
import EndoReferralDecision from "./EndoReferralDecision";
import { prepareContractDetails } from "./helpers";
import QuoteBanners from "./QuoteBanners";
import Quotes from "./Quotes";
import ReferralDecision from "./ReferralDecision";

const { Body, Data, Row, Head, Header } = Table;

const ContractDetails = ({
  allowChangesToggle,
  allowHistoryTooltip,
  clauseData,
  contractData,
  defaultShowChangesOnly,
  isCurrent,
  isEndorsement,
  schemaData,
  snapshotData,
}) => {
  const { push } = useHistory();
  const [showChangesOnly, setChangesOnly] = useState(defaultShowChangesOnly);
  const { showModal, closeModal } = useModal();
  const { mutateAsync: getAttachmentLink, ...getAttachmentLinkQuery } = useMutation(
    api.getAttachmentLink
  );
  const { isInternalEnv, checkPermissions, isProducingBroker } = useAuth(contractData);
  const { contractId, productRef } = useParams();
  const bespokeEndos = get(contractData, "bespoke_endorsements", []);
  const hasBespokeEndos = bespokeEndos.length > 0;
  const endorsementId = isEndorsement ? contractData.id : undefined;
  const referralReasons = get(contractData, "referralReasons", []);
  const isReferredCancellation = referralReasons.includes("Known losses.");
  const canCancelManual =
    isCurrent &&
    isEndorsement &&
    isReferredCancellation &&
    checkPermissions(ACTIONS.CANCEL_ENDORSEMENT_MANUAL);

  const canCreateEndoQuote =
    isCurrent &&
    isEndorsement &&
    !isReferredCancellation &&
    checkPermissions(ACTIONS.PROVIDE_COMMERCIAL_PRICE);

  const result = prepareContractDetails({
    contractData,
    snapshotData,
    schemaData,
    showChangesOnly,
    generateHistory: allowHistoryTooltip,
    isInternalEnv,
  });

  const handleDownloadClick = async ({ documentId }) => {
    const res = await getAttachmentLink({ contractId, productRef, documentId });
    const { url, fileName } = get(res, "data.data");

    downloadDocument(url, fileName);
  };

  const handleActions = (action) => {
    if (action.type === ACTIONS.CREATE_CLAUSE) {
      return showModal(MODALS.CREATE_CLAUSE, { endorsementId });
    }

    if (action.type === ACTIONS.EDIT_CLAUSE) {
      return showModal(MODALS.CLAUSE_EDIT, { clause: action.clause, endorsementId, contractData });
    }

    if (action.type === ACTIONS.EDIT_BESPOKE) {
      return showModal(MODALS.BESPOKE_EDIT, {
        bespoke: action.bespoke,
        endorsementId,
        contractData,
      });
    }

    return false;
  };

  const handleConfirmEdit = () => {
    closeModal();

    if (isEndorsement) {
      return push(compile(ROUTES.ENDORSEMENT_EDIT)({ productRef, contractId, endorsementId }));
    }

    return push(compile(ROUTES.CONTRACT_EDIT)({ contractId, productRef }));
  };

  return (
    <>
      <Banners contractData={contractData} />

      <div className="mb-4 xl:flex xl:-mx-3">
        <div className="bg-white rounded shadow p-4 mb-8 xl:w-1/2 xl:mx-3">
          <div className="flex items-center justify-between mb-4">
            <div>
              <H3>Policy details</H3>

              {allowChangesToggle && (
                <Toggle
                  className="mt-2"
                  onClick={() => setChangesOnly(!showChangesOnly)}
                  value={showChangesOnly}
                  labelText="Show changes only"
                />
              )}
            </div>

            {isCurrent && (
              <BG checkPermissions={checkPermissions}>
                <BG.Button
                  action={ACTIONS.UPDATE_SUBMISSION}
                  iconName="create"
                  onClick={() =>
                    showModal(MODALS.EDIT_QUOTE_WARNING_MODAL, {
                      handleConfirm: handleConfirmEdit,
                    })
                  }
                />
              </BG>
            )}
          </div>

          <Accordion id={contractId} isSticky>
            <AccordionPanelDocuments
              key="documents"
              headingText="Documents"
              contractData={contractData}
              endorsementId={endorsementId}
            />

            {result.map((section) => (
              <Accordion.Panel key={section.sectionKey} headingText={section.sectionTitle}>
                <Table>
                  <Body>
                    {section.data.map((datapoint) => {
                      if (
                        datapoint.datapointComponent === "SectionRepeater" ||
                        datapoint.datapointComponent === "InputMatrix"
                      ) {
                        return (
                          <Row
                            key={datapoint.datapointKey}
                            className="border-b border-gray-300 last:border-0"
                          >
                            <Data colSpan="2">
                              <div>
                                <Table className="border border-gray-500">
                                  <Head className="bg-gray-50">
                                    {datapoint.columns.map((column) => (
                                      <Header key={column.key}>{column.title}</Header>
                                    ))}
                                  </Head>
                                  <Body>
                                    {datapoint.rows.map((row, rowIndex) => (
                                      <Row
                                        key={rowIndex.toString()}
                                        className="border-b border-gray-300 last:border-0"
                                      >
                                        {row.map((data, columnIndex) => (
                                          <Data key={columnIndex.toString()}>
                                            <Datapoint
                                              allowChangesToggle={allowChangesToggle}
                                              allowHistoryTooltip={allowHistoryTooltip}
                                              data={data}
                                            />
                                          </Data>
                                        ))}
                                      </Row>
                                    ))}
                                  </Body>
                                </Table>
                              </div>
                            </Data>
                          </Row>
                        );
                      }

                      if (datapoint.datapointComponent === "InputFile") {
                        return (
                          <Row
                            key={datapoint.datapointKey}
                            className="border-b border-gray-300 last:border-0"
                          >
                            <Data className="w-3/5">
                              <div className="flex items-center justify-between">
                                <div>{datapoint.datapointTitle}</div>
                                <Button
                                  className="h-10 px-4 ml-4"
                                  onClick={() => handleDownloadClick(datapoint?.currValue)}
                                  isDisabled={getAttachmentLinkQuery.isLoading}
                                >
                                  Download
                                </Button>
                              </div>
                            </Data>
                            <Data className="w-2/5">
                              <div className="break-all">{datapoint?.currFormattedValue}</div>
                            </Data>
                          </Row>
                        );
                      }

                      return (
                        <Row
                          key={datapoint.datapointKey}
                          className="border-b border-gray-300 last:border-0"
                        >
                          <Data className="w-3/5">{datapoint.datapointTitle}</Data>
                          <Data className="w-2/5 whitespace-pre">
                            <Datapoint
                              allowChangesToggle={allowChangesToggle}
                              allowHistoryTooltip={allowHistoryTooltip}
                              data={datapoint}
                            />
                          </Data>
                        </Row>
                      );
                    })}
                  </Body>
                </Table>
              </Accordion.Panel>
            ))}

            {!showChangesOnly && (
              <AccordionPanelClauses
                key="clauses"
                headingText="Clauses"
                clauseData={clauseData}
                handleActions={handleActions}
                isCurrent={isCurrent}
                checkPermissions={checkPermissions}
              />
            )}

            {!showChangesOnly && hasBespokeEndos && (
              <AccordionPanelBespoke
                bespokeEndos={bespokeEndos}
                handleActions={handleActions}
                isEndorsement={isEndorsement}
              />
            )}
          </Accordion>
        </div>

        <div className="mb-4 xl:w-1/2  xl:mx-3">
          <QuoteBanners contractData={contractData} />

          {isCurrent && !isEndorsement && checkPermissions(ACTIONS.PROVIDE_COMMERCIAL_PRICE) && (
            <ReferralDecision className="mb-8" contractData={contractData} />
          )}

          {canCreateEndoQuote && (
            <EndoReferralDecision
              className="mb-8"
              contractData={contractData}
              schemaData={schemaData}
              endorsementId={endorsementId}
            />
          )}

          {canCancelManual && (
            <EndoCancelReferralDecision
              className="mb-8"
              contractData={contractData}
              schemaData={schemaData}
              endorsementId={endorsementId}
            />
          )}

          <Quotes
            checkPermissions={checkPermissions}
            contractData={contractData}
            isCurrent={isCurrent}
            isEndorsement={isEndorsement}
            isProducingBroker={isProducingBroker}
            schemaData={schemaData}
          />
        </div>
      </div>
    </>
  );
};

ContractDetails.propTypes = {
  contractData: PropTypes.object.isRequired,
};

const areEqual = (prevProps, nextProps) =>
  JSON.stringify(prevProps.snapshotData) === JSON.stringify(nextProps.snapshotData) &&
  JSON.stringify(prevProps.contractData) === JSON.stringify(nextProps.contractData) &&
  JSON.stringify(prevProps.clauseData) === JSON.stringify(nextProps.clauseData);

export default memo(ContractDetails, areEqual);
