/* eslint-disable no-restricted-globals */

import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import sumBy from "lodash.sumby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { ACTIONS } from "../../constants";
import Button from "../components/Button";
import Form from "../components/Form";
import H3 from "../components/H3";
import InputPercentage from "../components/InputPercentage";
import Modal from "../components/Modal";
import { useContractQuery } from "../hooks";

const ChangeCommissionModalForm = ({ handleClose }) => {
  const { contractId, productRef, contractData, updateContract, isUpdating } = useContractQuery();
  const [formValues, setFormValues] = useState(contractData.brokerCommissions.commissionBreakdown);

  const onChange = (value, name) => {
    const clonedFormValues = cloneDeep(formValues);
    set(clonedFormValues, name, { ...clonedFormValues[name], commission: value });
    setFormValues(clonedFormValues);
  };

  const handleSubmit = async () => {
    await updateContract({
      contractId,
      productRef,
      data: {
        type: ACTIONS.UPDATE_CONTRACT_COMMISSION,
        payload: { commissionBreakdown: formValues },
      },
    });
    handleClose();
  };

  return (
    <div className="p-6">
      <H3>Broker commission split</H3>
      <div className="text-gray-700 text-sm mb-6">
        Total may not exceed {contractData.brokerCommissions.commissionCap}%
      </div>
      <Form onSubmit={handleSubmit}>
        {formValues.map((f, index) => (
          <InputPercentage
            className="mb-8"
            decimalScale={2}
            isAllowed={({ floatValue, value }) => {
              const newTotal =
                sumBy(
                  formValues.filter((x) => x.id !== f.id),
                  "commission"
                ) + floatValue;
              return value === "" || newTotal <= contractData.brokerCommissions.commissionCap;
            }}
            isRequired
            labelText={f.name}
            name={index}
            value={f.commission}
            onChange={onChange}
          />
        ))}
        <div className="flex">
          <Button
            className="mr-4"
            isDisabled={isUpdating || formValues.some((f) => isNaN(f.commission))}
            kind="primary"
            type="submit"
          >
            Submit
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

const ChangeCommissionModal = ({ handleClose }) => (
  <Modal handleClose={handleClose} headingText="Amend commission" isLoading={false}>
    <ChangeCommissionModalForm handleClose={handleClose} />
  </Modal>
);

ChangeCommissionModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default ChangeCommissionModal;
