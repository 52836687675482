import unset from "lodash.unset";

export const cleanObject = (obj) => {
  Object.entries(obj).forEach(([key, v]) => {
    if (!Array.isArray(obj[key])) {
      if (v && typeof v === "object") cleanObject(v);
      if (JSON.stringify(v) === "{}" || v === "") unset(obj, key);
    }
  });
};
