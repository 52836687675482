/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import clsx from "clsx";
import React, { Children } from "react";
import { Link, useLocation } from "react-router-dom";
import { stringifyParams } from "../../helpers/stringifyParams";
import useStickyState from "../hooks/useStickyState";
import Icon from "./Icon";

const Dot = ({ value }) => (
  <div
    className={clsx(
      "ml-1 flex items-center justify-center text-white w-4 rounded text-semibold",
      value ? "bg-red-900" : "bg-green-900"
    )}
    style={{ fontSize: "10px" }}
  >
    {value}
  </div>
);

const Tab = ({ id, isSelected, value, onClick }) => (
  <div
    className={clsx(
      "cursor-pointer border-b-2 border-transparent px-3 py-2 flex -my-px",
      isSelected && "border-blue-500"
    )}
    onClick={onClick}
  >
    {id} {(value || value === 0) && <Dot className="w-4" value={value} />}
  </div>
);

const Tabs = ({ children }) => {
  const first = Children.toArray(children).map((child) => child.props.id)[0];
  const [selectedTab, setSelectedTab] = useStickyState(first, "debuggerTabs");
  const { render } = Children.toArray(children).filter((c) => selectedTab === c.props.id)[0].props;
  const isFn = render && render instanceof Function;

  return (
    <div className="text-xs">
      <div className="font-semibold border-b border-gray-500">
        <div className="bg-gray-200 flex">
          {Children.toArray(children).map((child) => {
            const isSelected = selectedTab === child.props.id;

            return React.cloneElement(child, {
              ...child.props,
              isSelected,
              key: child.props.id,
              onClick: () => setSelectedTab(child.props.id),
            });
          })}
        </div>
      </div>
      <div className="relative p-4">{isFn ? render() : render}</div>
    </div>
  );
};

const Debugger = ({ handleClose, referralsData, validationErrors, formValues, isFormValid }) => {
  const isLocalSchemaEnabled = JSON.parse(process.env.LOCAL_SCHEMA_OVERRIDE);
  const location = useLocation();

  return (
    <div
      className="fixed right-12 bottom-0 w-1/3 pb-24 overflow-y-scroll border-gray-500 border z-10 bg-white rounded-t shadow-lg"
      style={{ height: "50vh" }}
    >
      <div>
        <Tabs>
          <Tab
            id="Referrals"
            value={referralsData.length}
            render={
              <pre className="mb-6">
                <p className="font-bold mb-4">Referral reasons</p>
                {JSON.stringify(referralsData, null, 2)}
              </pre>
            }
          />
          <Tab
            id="Validation"
            value={validationErrors.length}
            render={
              <pre className="mb-6">
                <p className="font-bold mb-4">Validation errors</p>
                {JSON.stringify(
                  validationErrors.map((e) => e.message),
                  null,
                  2
                )}
              </pre>
            }
          />
          <Tab
            id="Form"
            render={
              <pre className="mb-6">
                {isLocalSchemaEnabled && (
                  <pre className="mb-6">
                    <p className="font-bold mb-4">Local schema enabled</p>
                  </pre>
                )}
                <p className="font-bold mb-4">Form values</p>

                {isFormValid && <p className="mb-4 font-bold text-green-500">Valid</p>}

                {!isFormValid && <p className="mb-4 font-bold text-red-500">Invalid</p>}

                <div className="mb-4">{JSON.stringify(formValues, null, 2)}</div>
              </pre>
            }
          />

          <Tab
            id="Link"
            render={() => {
              const params = stringifyParams({ initial_form_data: formValues }, { encode: false });
              const url = `${location.pathname}${params}`;

              return (
                <div className="mb-6 font-mono break-all">
                  <p className="font-bold mb-4">Form link:</p>
                  <Link to={url} className="hover:underline text-blue-600">
                    {url}
                  </Link>
                </div>
              );
            }}
          />
        </Tabs>

        <Icon
          name="cross"
          className="cursor-pointer w-3 m-3 absolute right-0 top-0 fill-current text-gray-800"
          onClick={handleClose}
        />
      </div>
    </div>
  );
};

export default Debugger;
