import get from "lodash.get";
import groupBy from "lodash.groupby";
import sortBy from "lodash.sortby";
import React from "react";
import { ACTIONS, MODALS, QUOTE_STATUSES, QUOTE_TYPES } from "../../../constants";
import BG from "../../components/ButtonGroup";
import H3 from "../../components/H3";
import { useModal } from "../../hooks";
import QuoteCard from "./QuoteCard";

const selectRejected = (quote) => quote.status === QUOTE_STATUSES.REJECTED_NOT_TAKEN_UP;
const selectArchived = (quote) => quote.status === QUOTE_STATUSES.ARCHIVED;
const selectSubmitted = (quote) => quote.status === QUOTE_STATUSES.SUBMITTED;
const selectBound = (quote) => quote.status === QUOTE_STATUSES.BOUND;
const selectCancelled = (quote) => quote.status === QUOTE_STATUSES.CANCELLED;
const selectDeclined = (quote) => quote.status === QUOTE_STATUSES.DECLINED;
const selectNotCommercial = (quote) => quote.type !== QUOTE_TYPES.COMMERCIAL;

const Quotes = ({
  contractData,
  isCurrent,
  isEndorsement,
  checkPermissions,
  isProducingBroker,
}) => {
  const quotesData = get(contractData, "quotes", []);
  const archivedQuotesList = quotesData.filter(selectArchived);
  const submittedQuotes = sortBy(quotesData.filter(selectSubmitted), (q) => q.rates.deductible);
  const groupedArchivedQuotes = groupBy(archivedQuotesList, (q) => q.type);
  const rejectedQuotes = sortBy(quotesData.filter(selectRejected), (q) => q.rates.deductible);
  const boundQuotesList = quotesData.filter(selectBound);
  const cancelledQuotes = quotesData.filter(selectCancelled);
  const declinedQuotes = quotesData.filter(selectDeclined);
  const commercialArchivedQuotes = groupedArchivedQuotes[QUOTE_TYPES.COMMERCIAL] || [];
  const otherArchivedQuotes = archivedQuotesList.filter(selectNotCommercial);
  const { showModal } = useModal();

  return (
    <>
      {[...boundQuotesList, ...submittedQuotes].length > 0 && (
        <QuoteCard
          contractData={contractData}
          heading={
            <div className="flex items-center justify-between mb-4 p-4">
              <H3>Quote(s)</H3>
              {process.env.DEFAULT_PRODUCT_REF !== "ah" &&
                !isProducingBroker &&
                isCurrent &&
                !isEndorsement && (
                  <BG checkPermissions={checkPermissions}>
                    <BG.Button
                      action={ACTIONS.UPDATE_CONTRACT_COMMISSION}
                      iconName="create"
                      onClick={() => showModal(MODALS.CHANGE_COMMISSION)}
                    />
                  </BG>
                )}
            </div>
          }
          isCurrent={isCurrent}
          isEndorsement={isEndorsement}
          quotesData={[...boundQuotesList, ...submittedQuotes]}
        />
      )}

      {cancelledQuotes.length > 0 && (
        <QuoteCard
          contractData={contractData}
          headingText="Cancelled quote(s)"
          isCurrent={isCurrent}
          isEndorsement={isEndorsement}
          quotesData={cancelledQuotes}
        />
      )}

      {rejectedQuotes.length > 0 && (
        <QuoteCard
          contractData={contractData}
          headingText="Rejected quote(s)"
          isCurrent={isCurrent}
          isEndorsement={isEndorsement}
          quotesData={rejectedQuotes}
        />
      )}

      {declinedQuotes.length > 0 && (
        <QuoteCard
          contractData={contractData}
          headingText="Declined quote(s)"
          isCurrent={isCurrent}
          isEndorsement={isEndorsement}
          quotesData={declinedQuotes}
        />
      )}

      {commercialArchivedQuotes.length > 0 && (
        <QuoteCard
          contractData={contractData}
          headingText="Archived underwriter quote(s)"
          isCurrent={isCurrent}
          isEndorsement={isEndorsement}
          quotesData={commercialArchivedQuotes}
        />
      )}

      {otherArchivedQuotes.length > 0 && (
        <QuoteCard
          contractData={contractData}
          headingText="Archived quote(s)"
          isCurrent={isCurrent}
          isEndorsement={isEndorsement}
          quotesData={otherArchivedQuotes}
        />
      )}
    </>
  );
};

export default Quotes;
